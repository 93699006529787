.brand-actions-item-header .ant-collapse-header {
  padding: 0 0 12px 0 !important;
}
.brand-actions-item-header .ant-collapse-content .ant-collapse-content-box {
  padding: 0 !important;
}
.ams-action-filter .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  max-width: 120px !important;
}
.alert-value-select .ant-select-selector .ant-select-selection-item {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #8e8e93 !important;
}
.alert-value-select .ant-select-arrow {
  position: static !important;
  margin-top: none !important;
}
.alert-value-select .ant-select-selector {
  max-width: 140px !important;
  padding: 0 !important;
  width: auto !important;
}
.alert-value-select {
  display: flex !important;
  width: auto !important;
  flex-direction: row !important;
  align-items: center !important;
}
.alert-value-select .ant-select-selector .ant-select-selection-item {
  padding-right: 0 !important;
}
.alert-value-dropdown .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner .ant-select-item-option-active {
  background-color: #fff !important;
  padding: 0 !important;
}
.alert-value-dropdown {
  max-width: 170px !important;
  width: 170px !important;
  left: 570px !important;
  border-radius: 8px !important;
}
.account-manager-select {
  width: auto !important;
  max-height: 40px !important;
}
.account-manager-select .ant-select-selector {
  background-color: #f2f2f7 !important;
  border-radius: 50px !important;
  height: 100% !important;
}
.account-manager-dropdown {
  border-radius: 8px !important;
  width: auto !important;
}
.account-manager-dropdown .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner .ant-select-item-option-active {
  background-color: #fff !important;
  padding: 0 !important;
}
.ant-brand-actions-item-collapse .ant-collapse-item {
  border-bottom: none !important;
}

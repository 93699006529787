.imageUploadWrapper
  margin-bottom: 2.4rem
  .imagePreview,
  .dropZone
    width: 45rem
    height: 15.8rem
    border-radius: 5px
    object-fit: cover
    margin-bottom: 1.6rem

  .dropZone
    border: 2px dashed #a6a6a6
    transition: all ease-in-out 0.2s
    &.isDraggingOver
      border-color: #f09440

  .sizeNote
    font-size: 1.4rem
    font-weight: 500
    margin-bottom: 0.8rem
    color: #a6a6a6
  .uploadButton
    display: flex
    justify-content: center
    align-items: center
    width: 16.2rem
    height: 4rem
    border-radius: 4px
    background-color: #f09440
    color: #ffffff
    font-size: 1.4rem
    cursor: pointer
    font-weight: bold
.changeImage
  padding: 8px 12px 
  border-radius: 8px 
  background-color: #ff9000
  color: #ffffff
  font-size: 14px
  cursor: pointer
  font-weight: 700
  width: 74px
  height: 35px
.wrapper
  height: 100%
  width: 100% !important
  background-color: #ffffff
  border: solid 1px #e5e5ea
  padding: 24px 24px 16px 24px
  // display: flex
  // justify-content: space-between
  // flex-direction: row
  // align-items: flex-start
  .icon
    width: 2rem
    height: 2rem
  .btn
    width: 4rem
    height: 4rem
    border-radius: 2rem
    background-color: #efefef00
    outline: 0
  .pickupWrapper
    display: flex
    flex-direction: column
    flex: 1
    .pickupTitle
      font-size: 14px
      color: #1c1c1e
    .pickupPercentage
      font-size: 24px
      font-weight: bold
      color: #000000
  .title
    font-size: 20px
    font-weight: 900
    color: #1a1a1a
  .labelWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width: 100%
    .label
      font-size: 20px
      font-weight: bold
      text-align: left
      color: #1c1c1e
    .iconWrapper
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 120px
      background-color: #eae8f3
      outline: none
      border: none
      .icon
        width: 24px
        height: 24px

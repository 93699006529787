@mixin form-shared
  .title
    margin-bottom: 4rem
    font-size: 2.4rem
    font-weight: 900
    display: flex
    justify-content: space-between
    align-items: center
    color: #000000
    width: 100%
    span
      font-size: 2rem
      cursor: pointer
  .submit
    width: 20rem
    height: 5.6rem
    border-radius: 4px
    background-color: #f09440
    color: #ffffff
    font-size: 1.8rem
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
    margin-top: 3.2rem
    transition: all .2s ease-in-out
    &:disabled
      background-color: #cccccc
      color: #666666

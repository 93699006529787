@import ../../sass/colors


.innerClass
  display: flex
  justify-content: center
  align-items: center
  text-decoration: none !important
  list-style-type: none !important
  margin: 3rem 0
  a:hover
    color: initial
.activeClass,
.activeLinkClass,
.itemClass
  margin: 0 2rem
  width: 3rem
  cursor: pointer
  height: 3rem
  font-size: 1.8rem
  display: flex
  justify-content: center
  align-items: center
  a
    width: 100%
    height: 100%
    color: black
    list-style-type: none !important
    text-decoration: none !important
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
.activeClass
  background-color: #fdbb11
  border-radius: .3rem
  a
    color: white


.itemClassPrev,
.itemClassNext,
.itemClassFirst,
.itemClassLast
  width: 3rem
  height: 3rem
  cursor: pointer
  font-size: 1.8rem
  border-radius: .3rem
  background-color: white
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.11)
  display: flex
  justify-content: center
  align-items: center
  a
    width: 100%
    height: 100%
    color: black
    text-decoration: none !important
    list-style-type: none !important
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center

.wrapper
  display: flex
  width: 100%
  height: 100%
  flex-direction: column
  padding: 4rem 2rem
  .rejectedText
    margin: auto
  .filterWrapper
    display: flex
    justify-content: space-between
    align-items: center
    .btnWrapper
      display: flex
      align-items: center
      .filter
        padding: .5rem 1rem
        border-radius: 4rem
        display: flex
        align-items: center
        .img
          height: 3rem
          width: 3rem
        .text
          color: #9B9B9B
          margin: 0 1rem
          font-size: 2.2rem
  .usersWrapper
    margin-top: 1rem
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.12)

.btn,.btnActive
  background-color: white
  min-width: 6rem
  border: 1px solid #2d2d2d
  padding: .5rem 1rem
  font-size: 1.6rem
  border-radius: 5px
  margin: 0 .5rem
  color: black
  transition: all .2s
  outline: none !important

.btn:hover,
.btnActive
  border: 1px solid $color-shopx-main-theme
  background-color: rgba(255,237,201,.43)
  color: $color-shopx-main-theme

.wrapper
  width: 51.2rem
  height: 109.3rem
  box-shadow: -2px 2px 54px 0 rgba(0, 0, 0, 0.1)
  background-color: #ffffff
  display: flex
  flex-direction: column
  .addUserWrapper
    display: flex
    justify-content: space-between
    flex-direction: row
    padding: 12.5rem 3.3rem 4rem 2.9rem
    .addUserTitle
      font-size: 2.4rem
      font-weight: 900
      line-height: 0.83
      color: #000000
    .close
      cursor: pointer  

  .form
    display: flex
    flex-direction: column    
    padding: 0 3.3rem
    align-items: center
    .input
      width: 44.9rem 
    .separator
      margin: 1rem 0         
    .btn
      width: 21.4rem
      height: 5.6rem
      border-radius: 5px
      background-color: #f09440 
      font-size: 1.6rem
      font-weight: bold
      letter-spacing: 0.11px
      text-align: center
      color: #ffffff
    .userRole
      font-size: 1.6rem
      font-weight: 900
      display: flex
      align-self: flex-start
      color: #1a1a1a  
    .userRoleWrapper
      display: flex
      flex-direction: row
      align-self: flex-start
      padding: 0 .2rem      

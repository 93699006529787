@import ../../sass/colors

.header
  height: 7rem
  background-color: #fff
  border-bottom: 1px solid $color-border
  width: 100%
  display: flex
  justify-content: space-between
  padding: 0 5rem
  align-items: center
  .search
    display: flex
    align-items: center
    width: 50rem
    border: 1px solid $color-border
    border-radius: 20px
    height: 40px
    .logo
      margin: 0 1rem
      height: 2rem
    .input
      outline: none
      color: $color-grey-paragraph
      border: none
      font-size: 2rem
      padding: .5rem
      width: 90%
      height: 100%
    .input:disabled
      background-color: $color-border
  .user
    background-color: white
    padding: .5rem 1rem
    border-radius: 4rem
    display: flex
    align-items: center
    .userImg
      height: 3.75rem
      border-radius: 50%
    .userName
      margin: 0 1rem
      font-size: 1.5rem
.title
  font-weight: 600
  font-size: 16px
  color: #1C1C1E

.takeActionWrapper
  display: flex
  flex-direction: row
  align-items: center
  .icon
    width: 32px
    height: 32px
    cursor: pointer
    margin-right: 8px
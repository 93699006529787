.wrapper
  width: 56.8rem
  border-radius: 1rem
  box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.12), 0 1.6rem 1.6rem 0 rgba(0, 0, 0, 0.13)
  background-color: #ffffff
  display: flex
  flex-direction: column
  .btnWrapper
    width: 56.8rem
    height: 8rem
    border-radius: 1rem
    background-color: #f2f2f2
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    padding-right: 2.4rem
    .add
      width: 15.7rem
      height: 4.8rem
      border-radius: 5px
      background-color: #f09440
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
      outline: none
    .cancel
      width: 14.4rem
      height: 4.8rem
      border-radius: 4px
      border: solid 1px #ee8728
      background-color: #f2f2f2
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ee8728
      outline: none
  .addNewTagsWrapper
    display: flex
    flex-direction: column
    padding: 3.2rem
    .title
      font-size: 2.2rem
      font-weight: 500
      color: #2d2d2d
    .separator
      margin-top: 3.2rem
    .input
      border: none
      padding: 0
      flex: 1
      text-align: right

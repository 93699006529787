.main
    display: flex
    justify-content: center
    align-items: center
    .wrapper
        width: 561px
        display: flex
        flex-direction: column
        background-color: #fff
        border-radius: 4px
        .buttonWrapper
            height: 72px
            display: flex
            justify-content: flex-end
            align-items: center
            padding: 12px 24px
            box-shadow: inset 0 1px 0 0 #f2f2f7
            background-color: #f9f9fb
            .button, .buttonDisabled
                outline: none
                width: 99px
                height: 48px
                font-size: 16px
                font-weight: bold
                color: #fff
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 8px
                padding: 10px 24px
                border-radius: 4px
                background-color: #ffa351

            .buttonDisabled
                cursor: not-allowed
                opacity: 0.6
        .select
            padding: 16px 24px
            .dropdownLabel
                font-size: 16px
                font-weight: 600
                color: #1c1c1e
                margin-bottom: 8px
            .dateTimeWrapper
                height: 56px
                width: 100%
                align-self: stretch
                flex-grow: 0
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: center
                padding: 8px 16px
                border-radius: 4px
                background-color: #f2f2f7 !important
                cursor: default
                outline: none
                border: none !important
                box-shadow: none !important
                .dropdownIcon
                    width: 24px !important
                    height: 24px !important
                .datePicker
                    width: 100% !important
                    height: 100% !important
                    background-color: #f2f2f7 !important
                    div
                        width: 100% !important
                    input
                        height: 100% !important
                        padding: 0 !important
                        margin: 0 !important
                        background-color: #f2f2f7 !important
                        border: none !important
                        outline: none !important
                        font-size: 16px !important
                      
                        color: #1c1c1e !important

                        &:focus
                            outline: none !important
                            border: none !important
                            border-color: none !important

        .header
            margin-bottom: 24px
            padding: 16px 24px
            display: flex
            flex-direction: row
            align-content: center
            justify-content: space-between
            .title
                font-size: 20px
                font-weight: bold
                color: #333
            .closeWrapper
                width: 32px
                height: 32px
                display: flex
                padding: 4px
                border-radius: 70px
                background-color: #f2f2f7
                cursor: pointer
                .close
                    width: 24px
                    height: 24px

.wrapper
    display: flex
    justify-content: center
    align-items: center
    .content
        border-radius: 8px
        border: 1px solid #E5E5EA
        background-color: #FFF
        width: 561px
        .footer
            display: flex
            flex-direction: row
            align-content: center
            justify-content: flex-end
            background-color: #F9F9FB
            box-shadow: 0px 1px 0px 0px #F2F2F7 inset
            gap: 12px
            padding: 12px 24px
            .deleteBtn, .keepBtn
                display: flex
                height: 48px
                padding: 10px 24px
                justify-content: center
                align-items: center
                border-radius: 4px
                background-color: #E40031
                color:#FFF
                font-size: 16px
                font-weight: 700
                outline: none
            .deleteBtn
                min-width: 143px    
            .keepBtn
                color: #1C1C1E    
                background-color: #F2F2F7
        .posInfo
            display: flex
            flex-direction: column
            gap: 12px
            border-radius: 4px
            border-bottom: 1px solid #F2F2F7
            background: #F9F9FB
            padding: 28px 32px
            .posName, .posVendor
                color: #1C1C1C
                font-size: 18px
                font-weight: 400
            .posVendor
                color: #65656C
        .description
            color: #333
            font-size: 20px
            font-weight: 600
            padding: 24px 24px 16px 24px
        .header
            padding: 16px 24px
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            .closeIcon
                width: 24px
                height: 24px
                cursor: pointer
            .title
                color: #333
                font-size: 20px
                font-weight: 700

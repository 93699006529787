.any-select .ant-select-selector {
  background-color: #ffa351 !important;
  height: 48px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  color: #fff;
}
.any-select .ant-select-dropdown {
  min-width: auto !important;
  width: auto !important;
}
.select-country.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}
.select-country .ant-select-selector {
  height: 50px !important;
}
.countrySelector .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  align-items: center;
  display: flex;
}
.any-select .ant-select-selector .ant-select-selection-placeholder {
  color: #fff;
  font-weight: 700;
}
.ant-select {
  display: initial;
  width: 100%;
}
.addBrandArrow .ant-select-selector .ant-select-selection-item span {
  color: #fff !important;
  font-weight: 600;
}
.addBrandArrow .ant-select-arrow {
  color: #fff;
}
.selectedPlaceholder .ant-select-selector .ant-select-selection-placeholder {
  color: #1c1c1c;
  height: 52;
}
.arrowStyle .ant-select-arrow {
  color: #000;
  right: 20px;
}
.any-select {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #1c1c1c !important;
}
.any-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #ffa351;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.dropdown-button {
  position: relative;
  color: #fff;
  border: 1px solid #ffa351;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #ffa351;
  padding: 10px 10px;
  font-weight: bold;
  font-size: 14px;
  justify-self: center;
  height: 50px;
  margin-top: 0px;
  cursor: pointer;
}
.ant-picker-input-modifier .ant-picker-suffix {
  display: none !important;
}
.ant-picker-input-modifier .ant-picker-input > input {
  font-size: 16px !important;
  color: #1C1C1C !important;
  font-weight: 400 !important;
}

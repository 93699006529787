.wrapper
    max-width: 561px
    width: 100%
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 8px
    height: max-content
    max-height: 100%
    .sendBulkMessageWrapper
        padding: 24px
        border-top: 1px solid #E5E5EA
        .sendBulkMessageBtn, .disabledSendBulkMessageBtn
            display: flex
            justify-content: center
            align-items: center
            outline: none
            border: none
            height: 56px
            width: 100%
            font-weight: 700
            font-size: 18px
            color: #FFFFFF
            background: #FFA351
            border-radius: 8px
        .disabledSendBulkMessageBtn
            cursor: not-allowed    
            background: #F2F2F7
    .sendToWrapper
        padding: 40px 24px 0 24px
        display: flex
        flex-direction: row
        align-items: center
        .brandCounts
            font-weight: 600
            font-size: 16px
            color: #1C1C1C
        .icStore
            width: 19.39px
            height: 19.39px
        .sendToTitle
            margin-right: 12px
            font-weight: 700
            font-size: 16px
            color: #65656C
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 16px 24px
        border-bottom: 1px solid #E5E5EA
        .brandMessageIcon
            width: 31.03px
            height: 31.03px
            margin-right: 8.49px
        .headerTitle
            font-weight: 400
            font-size: 20px
            color: #1C1C1C

@font-face
  font-family: "admin-icons"
  src: url("./fonts/admin-icons.eot?d4u6sg")
  src: url("./fonts/admin-icons.eot?d4u6sg#iefix") format("embedded-opentype")
  src: url("./fonts/admin-icons.ttf?d4u6sg") format("truetype")
  src: url("./fonts/admin-icons.woff?d4u6sg") format("woff")
  src: url("./fonts/admin-icons.svg?d4u6sg#admin-icons") format("svg")
  font-weight: normal
  font-style: normal
  font-display: block


//noinspection ALL
[class^="icon-"],
[class*=" icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "admin-icons" !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


.icon-sign:before
  content: "\e90b"



.icon-trash .path1:before
  content: "\e902"
  color: rgb(219, 48, 48)
  opacity: 0.2

.icon-trash .path2:before
  content: "\e903"
  margin-left: -1em
  color: rgb(219, 48, 48)

.icon-trash .path3:before
  content: "\e904"
  margin-left: -1em
  color: rgb(219, 48, 48)

.icon-dots:before
  content: "\e900"

.icon-add:before
  content: "\e901"

.icon-clock:before
  content: "\e907"

.icon-close:before
  content: "\e908"

.icon-conversion-rate:before
  content: "\e909"

.icon-edit:before
  content: "\e90a"

.icon-icon-delete:before
  content: "\e931"

.icon-icon-filter:before
  content: "\e932"

.icon-icon-time:before
  content: "\e933"

.icon-keyboard:before
  content: "\e934"

.icon-mask:before
  content: "\e959"

.icon-no-love .path1:before
  content: "\e95a"
  color: rgb(249, 0, 0)

.icon-no-love .path2:before
  content: "\e95b"
  margin-left: -1em
  color: rgb(159, 159, 159)

.icon-noun_Phone:before
  content: "\e95c"

.icon-num-customers:before
  content: "\e95d"

.icon-num-redemptions:before
  content: "\e95e"

.icon-paid:before
  content: "\e95f"

.icon-phone:before
  content: "\e960"

.icon-profile-drop:before
  content: "\e961"

.icon-redemptions-rate .path1:before
  content: "\e962"
  color: rgb(254, 190, 25)

.icon-redemptions-rate .path2:before
  content: "\e963"
  margin-left: -1.138671875em
  color: rgb(255, 255, 255)

.icon-redemptions-rate .path3:before
  content: "\e964"
  margin-left: -1.138671875em
  color: rgb(254, 190, 25)

.icon-redemptions-rate .path4:before
  content: "\e965"
  margin-left: -1.138671875em
  color: rgb(254, 190, 25)

.icon-redemptions-rate .path5:before
  content: "\e966"
  margin-left: -1.138671875em
  color: rgb(254, 190, 25)

.icon-search:before
  content: "\e967"

.icon-tower:before
  content: "\e968"

.icon-trial:before
  content: "\e969"

.icon-up-arrow:before
  content: "\e96a"

.icon-warning:before
  content: "\e96b"

.icon-image-dropzone .path1:before
  content: "\e905"
  color: #fff

.icon-image-dropzone .path2:before
  content: "\e906"
  color: #000
  margin-left: -2.84765625em


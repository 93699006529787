.wrapper
    display: flex
    flex-direction: column
    padding: 32px
    .amsWrapper
        padding: 8px 12px
        height: 40px
        background: #F2F2F7
        border-radius: 50px
        font-weight: 400
        font-size: 14px
        color: #1C1C1C
    .container
        display: flex
        flex-direction: row
        align-items: flex-start
.dropdownWrapper
    display: flex
    flex-direction: column
    background-color: #fff
    .dropdownElementWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 4px 12px
        cursor: pointer
        .checkboxWrapper
            width: 24px
            height: 24px
            border: 1px solid #E5E5EA
            padding: 3px
            display: flex
            justify-content: center
            align-items: center
            box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
            border-radius: 4px
            margin-right: 12px
        .dropdownElementLabel
            font-weight: 400
            font-size: 16px
            color: #1C1C1C

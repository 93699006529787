
.NavigationItems {
    list-style: none;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 95%;
}


.bar{
    display: flex;
    margin: 0 2.5rem;
    padding: .5rem 0;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    color: inherit;
}
.title{
    margin: 0 1rem;
}

.wrapper
    display: flex
    flex-direction: row
    padding: 24px
    margin-bottom: 12px
    .card
        margin-right: 24px
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        width: 100%
        padding: 16px
        border-radius: 4px
        border: solid 1px #e5e5ea
        background-color: #fff
        .countryCard
            width: 64px
            height: 64px
            display: flex
            justify-content: center
            align-items: center
            padding: 16px
            border-radius: 4px
            background-color: #eae8f3
            .countryFlag
                width: 32px
                height: 32px
        .activeLocation
            font-size: 14px
            margin-top: 24px
            color: #000
        .count
            font-size: 24px
            font-weight: bold
            color: #1c1c1e
    .card:last-child
        margin-right: 0

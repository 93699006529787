.btn

  height: 60px
  border-radius: 5px
  font-size: 18px
  font-weight: 700
  line-height: 60px
  padding: 0px 25px
  border: solid 1px transparent

  color: white
  &-login
    width: 100%
    &:active &:focus
      outline: none
    &-primary
      width: 100%
      background-color: #FFA351
      &:hover
        background-color: #fff
        color: #FFA351
        cursor: pointer
        border-color: #FFA351

    &-disabled
      background: #e8f0fe
      color: black
      cursor: not-allowed
    &-transparent
      background: none
      border: none
      color: #FFA351
      cursor: pointer
.header
  height: 40px
  width: 100%
  display: flex
  justify-content: center
  aligncitems: center
  background: #EAE8F3
  border-radius: 10px 10px 0px 0px
  p
    font-size: 20px
    font-weight: 700
    color: #503E9D
    line-height: 40px
h1.header
  font-size: 24px
  font-weight: 700
  maring: 0px
.container
  background: #fff
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
.logoWrapper
  display: flex
  justify-content: center
  margin-bottom: 20px
  padding: 20px 10px
  height: auto
  .logo
    width: 150px
    margin: 10px auto

.formWrapper
  height: auto
  border-radius: 10px
  border: solid 1px #eee
  justify-content: center
  align-items: center
  align-content: center
  width: 35vw

.formControl
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  height: auto
  width: 100%
.separator
  margin: 0 8px  
  // margin: 16px 25px 0

.label
  font-weight: 600
  font-size: 16px
  line-height: 22px
  color: #1C1C1C
input.input

  padding: 10px
  height: 60px
  border: solid 1px #eee
  border-radius: 5px
  width: 100%
  &:focus
    outline: 1px solid #FFA351

.invalid
  border: solid 1px #f00
.valid
  border: solid 1px #0f0
.center
  align-items: center
.formContainer
  background-color: #FFF
  border: 1px solid #F2F2F7
  padding: 16px
  margin: 16px
  border-radius: 8px
  background: #F9F9FB

.divider
  display: flex
  flex-direction: row
  align-items: flex-start
  height: auto
.infoMessage
  display: flex
  align-items: center
  justify-content: flex-start
  width: 100%
  font-size: 12
  min-height: 16px
  color: #503E9D
  padding-top: 10px
  img
    height: 16px
    width: 16px
    margin: 0px 3px

.dateInput
  height: 60px
  width: 100%
  border-radius: 10px

.withIcon
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  border-radius: 10px
  position: relative

.iconWrapper
  position: absolute
  top: -1.5px
  left: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  height: 60px
  width: 30px
  margin-left: 10px
  z-index: 100

.inputField
  margin: 16px 0 0 0

.inputFieldContainer
  margin-top: 16px
  display: flex
  margin-left: 8px
  flex-direction: column
.wrapper
    max-height: 548px
    height: fit-content
    width: 561px
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 4px
    background-color: #ffffff
    border: 1px solid #D1D1D6
    padding: 24px
    .goBackToActionCenterWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-start
        max-width: 411px
        width: 100%
        padding-top: 24px
        .goBackToActionCenterIcon
            cursor: pointer
            width: 32px
            height: 32px
            margin-right: 8px
        .goBackToActionCenterTitle
            font-weight: 700
            font-size: 16px
            color: #1C1C1C
    .summaryOfActionsWrapper
        display: flex
        flex-direction: column
        padding: 12px 16px
        width: 411px
        max-height: 223px
        height: fit-content
        background: #FFFFFF
        border: 1px solid #D1D1D6
        border-radius: 4px
        .summaryOfActionsTitle
            font-weight: 700
            font-size: 14px
            color: #65656C
            margin-bottom: 12px
        .messagesWrapper
            display: flex
            flex-direction: column
            overflow: hidden
            overflow-y: scroll
            .messageWrapper
                display: flex
                flex-direction: column
                padding: 8px
                border-bottom: 2px solid #F2F2F7
                .date, .message
                    font-weight: 400
                    font-size: 12px
                    color: #65656C
                .date
                    margin-bottom: 8px
                .message
                    font-size: 14px

        .messagesWrapper::-webkit-scrollbar
            width: 0
    .toAllPoc
        font-weight: 700
        font-size: 14px
        color: #65656C
        margin-bottom: 24px
    .successfullyText
        font-weight: 700
        font-size: 16px
        color: #1C1C1C
    .successfullyIcon
        display: flex
        justify-content: center
        align-items: center
        width: 80px
        height: 80px
        background: #E7F8EE
        border-radius: 40px
        margin-bottom: 16px
    .headerWrapper
        width: 100%
        display: flex
        justify-content: flex-end
        padding-bottom: 24px
        .closeIcon
            cursor: pointer
            width: 32px
            height: 32px

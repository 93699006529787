.App {
  background-color: #FBFBFB;
  min-height: 100vh;
  font-family: "Avenir LT Std", "Nunito", "Raleway", "Open Sans", "Lato", sans-serif, "Cairo";
  font-weight: 400;
}

.rtl {
  direction: rtl;
}

.mainSection {
  display: flex;
}

.secondarySection {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
}

.navBar {
  min-width: 20rem;
  min-height: 100vh;
  padding: 5rem 0;
}

.posIntegration {
  display: flex;
  justify-content: center;
  align-items: center;
}
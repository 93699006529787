.NavigationItem {
    width: 100%;
    margin: 1rem 0;
}

.NavigationItem a {
    color: black;
    text-decoration: none;
    display: block;
    font-size: 2.5rem;
    cursor: pointer;
}

.NavigationItem a:hover{
    color: #FEBE19;
    text-decoration: none;
    background-color: rgba(254,190,25,.1);
    border-radius: 40px;
}

.NavigationItem a:active,
.NavigationItem a.active {
    color: #FEBE19;
    text-decoration: none;
    background-color: rgba(254,190,25,.1);
    border-radius: 40px;
}

.itemWrapper, .itemWrapperDisabled
  display: flex
  align-items: center
  padding: 1.7rem 1.6rem
  background-color: #ffffff
  border-bottom: 1px solid #e6e6e6
  .dateItem
    display: flex
    flex: 1
    justify-content: space-between
    align-items: center
    .dateWrapper
      display: flex
      flex-direction: column
      .fileNameWrapper
        display: flex
        flex-direction: column
        margin-top: 4px
        .fileNameLabel, .fileNameValue
          font-size: 14px
          font-weight: 900
          color: #2d2d2d
        .fileNameValue
          font-weight: 500
          width: 150px
      .text
        padding-top: 0.5rem
        font-size: 1.6rem
        font-weight: 500
        line-height: 1.13
        letter-spacing: 0.3px
        color: #2d2d2d
      .expiredText
        font-size: 1.2rem
        font-weight: 500
        line-height: 1.5
        letter-spacing: 0.23px
        color: #db3030
    .show
      display: block
    .hide
      display: none
  .rank
    font-size: 18px
    font-weight: 500
    flex: 0.49
    line-height: 1
    letter-spacing: 0.34px
    color: #2d2d2d
  .brandWrapper
    flex: 1.10
    display: flex
    flex-direction: row
    .avatarWrapper, .avatarWrapperDisabled
      width: 4rem
      height: 4rem
      border-radius: 1.2rem
      background-color: rgba(253, 187, 17, 0.12)
    .avatarWrapperDisabled
      background-color: rgba(255, 255, 255, 0.12)
    .brand
      margin-left: 1.6rem
      display: flex
      flex-direction: column
      justify-content: center
      .brandName
        font-size: 1.6rem
        font-weight: 900
        line-height: 1.13
        letter-spacing: 0.3px
        color: #2d2d2d
      .brandText
        padding-top: 0.5rem
        font-size: 1.6rem
        font-weight: 500
        line-height: 1.13
        letter-spacing: 0.3px
        color: #2d2d2d

  .promoCodeContent
    cursor: pointer
    display: flex
    flex-direction: column
    justify-content: center
    flex: 1
    .copyIdWrapper
      display: flex
      flex-direction: row
      .separating
        width: .1rem
        margin: 0 .8rem
        height: 1.4rem
        background-color: #d8d8d8
      .copyIdTitle
        text-decoration: underline
        font-size: 1.6rem
        line-height: 1.13
        font-weight: 500
        letter-spacing: 0.3px
        color: #41126c
      .promoCodeTitle
        font-size: 1.6rem
        font-weight: 900
        line-height: 1.13
        letter-spacing: 0.3px
        color: #2d2d2d
        &:hover
          text-decoration: underline
    .maskedPromo
      font-size: 14px
      font-weight: 500
      color: #f09440
      margin: 2px 0
    .promoCodeDescription
      font-size: 14px
      font-weight: 500
      line-height: 1.29
      letter-spacing: 0.26px
      padding-top: 0.5rem
      color: #2d2d2d
      &:hover
        text-decoration: underline

  .item
    cursor: pointer
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    .userDetailsWrapper
      display: flex
      flex-direction: row
      align-items: center
      .separator2
        color: #2d2d22
        font-size: 1.6rem
        font-size: 500
        margin: 0 8px
      .usageWrapper, .userWrapper
        display: flex
        flex-direction: row
        .usageTitle
          font-size: 1.6rem
          font-weight: 900
          letter-spacing: 0.3px
          color: #2d2d2d
        .usageDescription
          font-size: 1.6rem
          font-weight: 500
          margin: 0 0 0 .3rem
          letter-spacing: 0.3px
          color: #2d2d2d
    .promoCodeHeader
      display: flex
      flex-direction: row
      .copyId
        text-decoration: underline
        font-size: 1.6rem
        font-weight: 500
        line-height: 1.13
        letter-spacing: 0.3px
        color: #41126c

      .promoCodeTitle
        font-size: 1.6rem
        font-weight: 900
        line-height: 1.13
        letter-spacing: 0.3px
        color: #2d2d2d
    .headerWrapper
      display: flex
      flex-direction: row
      flex-direction: row
      align-items: center
      .header, .orderingType
        font-size: 1.6rem
        font-weight: 900
        line-height: 1.13
        letter-spacing: 0.3px
        color: #2d2d2d
      .orderingType
        padding: 0 4px
        color: #9b9b9b

    .text
      padding-top: 0.5rem
      font-size: 1.6rem
      font-weight: 500
      line-height: 1.13
      letter-spacing: 0.3px
      color: #2d2d2d
  .btnWrapper, .activeBtn
    display: flex
    flex-direction: row
    opacity: 0
    .editBtn
      width: 4.8rem
      height: 4.8rem
      outline: 0
      border-radius: 4rem
      background-color: rgba(65, 18, 108, 0.2)
    .editIcon
      width: 2.4rem
      height: 2.4rem
    .expire
      background-color: rgba(219, 48, 48, 0.2)
      width: 4.8rem
      height: 4.8rem
      border-radius: 4rem
      outline: 0
      display: flex
      justify-content: center
      align-self: center
      background-color: none
    .expireIcon
      width: 4.4rem
      height: 4.4rem
    .separator
      margin: 0 1.2rem
    .btn
      text-decoration: none
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      transition: all 0.2s
      width: 4.4rem
      height: 4.4rem
      transform: rotate(-360deg)
      border-radius: 4rem
      background-color: rgba(#f09440, 0.2)
      i
        font-size: 2.4rem
        color: #f09440

  &:hover
    .btnWrapper
      opacity: 1
  .activeBtn
    opacity: 1
.itemWrapperDisabled
  opacity: 0.4

.tooltip
  display: flex
  flex-direction: column
  .tooltipTitle
    font-size: 12px
    font-weight: 900
    line-height: 1.42
    color: #ffffff
  .tooltipDescription
    font-size: 10px
    line-height: 1.7
    color: #ffffff
.tooltipWrapper
  overflow: visible !important
  background-color: #000 !important
.tooltipTypeWrapper
  padding: 8px !important
  background-color: #000 !important
.branchesTooltipWrapper
  display: flex
  flex-direction: row
  width: 900px
  flex-wrap: wrap
.branchNamesTooltipWrapper
  width: 80%
  display: flex
  flex-direction: row
  flex-wrap: wrap
  padding: 10px
  .storeNameInTooltip
    font-size: 14px
    padding: 6px 2px
.popoverBtn
  width: 4.8rem
  height: 4.8rem
  transform: rotate(-360deg)
  border-radius: 40px
  outline: none
  display: flex
  background-color: #cec5d7
  cursor: pointer
  .popoverIcon
    width: 100%
    height: 100%
.popoverWrapper, .rtl
  display: flex
  flex-direction: column
  width: 100%
  .popoverItemWrapper
    display: flex
    height: 3.2rem
    border-bottom: .1rem solid #e6e6e6
    padding: .9rem 6.6rem .9rem 1.6rem
    align-items: center
    cursor: pointer
    // box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15)
    background-color: #ffffff
    .popoverItem, .popoverDeleteItem
      font-size: 1.4rem
      line-height: 1
      color: #1a1a1a
    .popoverDeleteItem
      color: #db3030

.main
    display: flex
    justify-content: center
    align-items: center
    .wrapper
        width: 561px
        display: flex
        flex-direction: column
        padding: 24px 0 0
        background-color: #fff
        border-radius: 4px
        .fleetWrapper
            padding: 0 32px
            width: 100%
        .toggleWrapper
            padding: 8px 24px
            border-bottom: 1px solid #f2f2f7    
            &:last-child
                border-bottom: none
        .header
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            padding: 18.5px 24px 42.5px 24px
            .title
                font-size: 20px
                font-weight: bold
                color: #333
            .closeWrapper
                width: 32px
                height: 32px
                display: flex
                justify-content: center
                align-items: center
                padding: 4px
                border-radius: 70px
                background-color: #f2f2f7
                cursor: pointer
                .closeIcon
                    width: 24px
                    height: 24px
.activeDeal
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 16px 0
    border-radius: 4px
    background-color: #fff
    .activeDealTitle
        font-size: 20px
        font-weight: bold
        color: #1c1c1e

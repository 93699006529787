button
  border: none
.genericButtonDefaults
  background-color: #f09440
  color: #ffffff
  .icon
    margin: -2px 7px
  

.dis
  background-color: #cccccc
  color: #666666



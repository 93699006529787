.fleetWrapper, .fleetAnimated
  animation: OrderingMethodAppear 0.3s ease-in-out
  padding: 2.4rem 3rem
  width: 100%
  height: 64px
  box-shadow: 0 6px 12px 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px
  border-radius: 4px
  border: solid 1px #e5e5ea

  .sw
    display: flex
    align-self: center !important

  .fleetTitle
    font-size: 20px
    font-weight: bold
    color: #1c1c1e
.fleetWrapper
  animation: none
@keyframes OrderingMethodAppear
  0%
    transform: translateY(-20px)
  100%
    transform: translateY(0px)

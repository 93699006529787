.main
    display: flex
    align-items: center
    justify-content: center
    .wrapper
        display: flex
        flex-direction: column
        align-items: center
        width: 744px
        background: #FFFFFF
        border: 1px solid #E5E5EA
        box-sizing: border-box
        border-radius: 4px
        .errorsWrapper
            display: flex
            flex-direction: column
            margin-top: 28px
            width: 100%
            padding-bottom: 18.5px
            .errors
                font-size: 18px
                color: #1C1C1E
                padding: 12px !important
                &:nth-child(even)
                    background-color: #f2f2f2
                &:nth-child(odd)
                    background-color: #FFF
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 18.5px 24px
        .label
            font-weight: bold
            font-size: 20px
            color: #333333

        .closeWrapper
            display: flex
            align-items: center
            justify-content: center
            padding: 4px
            width: 32px !important
            height: 32px !important
            background: #F2F2F7
            border-radius: 70px
            cursor: pointer
            .close
                width: 12px
                height: 12px

@import ../../../sass/colors

.wrapper
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
  background-color: white
  padding: 2rem 3rem
  color: $color-black-text
  cursor: pointer
  font-size: 1.8rem
  span
    margin: 0 1rem
    display: flex
    align-items: center
  .points,.quota
    display: flex
    width: 10%
  .date
    font-size: 1.4rem
    display: flex
    color: #9B9B9B
    align-items: center
    width: 10%
  .phone
    display: flex
    align-items: center
    width: 16%
    .logo
      margin: 0 .5rem
      width: 1.7rem
      height: 1.7rem
  .user,.pointsWrapper
    padding: .5rem 1rem
    border-radius: 4rem
    display: flex
    width: 17%
    align-items: center
    .userImg
      height: 4.5rem
      border-radius: 50%
    .userName,.points
      margin: 0 1rem
      font-size: 1.8rem

.btn
  border: none !important
  box-shadow: none !important
  outline: none !important
  color: white !important
  font-size: 1.8rem !important
  width: 10rem !important
  height: 4rem !important
  background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
  border-radius: 20px !important


.deleteBtn
  font-size: 1.4rem
  margin: 0 2rem 0 0
  color: red


.tags
  height: 4.4rem
  border-radius: 22px
  box-shadow: 0 2px 4px 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  margin: 1.2rem 1.6rem
  display: inline-flex
  flex-direction: row
  .btnWrapper
    display: none
    justify-content: center
    align-items: center
    align-self: center
    padding: 1.6rem .5rem 1.6rem 0
    outline: 0
    .delete, .edit
      display: flex
      justify-content: center
      align-items: center
      outline: 0
      border-radius: 40px
      width: 3.2rem
      height: 3.2rem
      background-color: rgba(219,48,48, 0.2)
    .edit
      background-color: rgba(65,18,108, 0.2)
    .icon
      width: 2rem
      height: 2rem
  .title
    font-size: 1.8rem
    font-weight: 900
    line-height: 1.06
    color: #1a1a1a
    padding: 1.3rem 1.6rem
  &:hover
    .btnWrapper
      display: flex

.main
    display: flex
    flex-direction: column
    .maskedPromocodeWrapper
        display: flex
        flex-direction: column
        padding: 16px
        width: 100%
        background: #F2F2F7
        border-radius: 4px
        margin: 16px 0px
        .maskedPromocodeInputsWrapper
            display: flex
            flex-direction: row
        .maskedPromocodeTitle
            font-weight: bold
            font-size: 20px
            margin-bottom: 16px
            color: #1C1C1E
    .wrapper
        display: flex
        flex-direction: row

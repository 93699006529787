.main
    display: flex
    flex-direction: column
    background-color: #fff
    border-radius: 5px

    .tableHeader
        padding: 0 24px
        display: flex
        flex-direction: row
        align-items: center
        box-shadow: inset 0 -1px 0 0 #f2f2f7, inset 0 1px 0 0 #f2f2f7
        background-color: #fff
        .brandName, .totalCustomers,.action, .activeDealsType, .brandStatus, .paymentStatus
            display: flex
            padding: 12px
            flex: 1.31
            width: 100%
            font-size: 14px
            font-weight: bold
            color: #8e8e93
        .paymentStatus
            flex: 0.78
        .totalCustomers
            flex: 0.8
        .action
            flex: 0.92
        .activeDealsType
            flex: 1
        .brandStatus
            flex: 1
    .filters
        display: flex
        flex-direction: row
        justify-content: flex-end
        align-items: center
        padding: 0 24px
        .select
            margin-left: 12px
            border-radius: 50px !important
            height: 40px
            button
                width: 100%
                height: 40px
                display: flex
                flex-direction: row
                align-items: center
                padding: 8px 12px
                border-radius: 50px
                img
                    width: 12px !important
                    height: 12px !important

    .searchAndTitleWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 24px
        .title
            font-size: 20px
            color: #1c1c1e
        .inputWrapper
            display: flex
            flex-direction: row
            align-items: center
            width: 329px
            height: 40px
            padding: 8px 16px
            border-radius: 4px
            background-color: #f2f2f7
            .searchIcon
                width: 16px
                height: 16px
            .input
                height: 100%
                width: 100%
                font-size: 14px
                outline: none
                border: none
                background-color: #f2f2f2
                color: #000
            .input::placeholder
                color: #8e8e93

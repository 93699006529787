@import "./banner-card"

.wrapper
  @include banner-card-layout
  position: relative
  .bannerImage
    position: absolute
    width: 100%
    height: 100%
    border-radius: 13px
    z-index: 1
    object-fit: cover
  .targetedUsers
    transition: all ease-in-out .2s
    position: absolute
    width: 100%
    height: 100%
    background-color: #ffffff
    border-radius: 13px
    display: flex
    align-items: flex-end
    justify-content: space-between
    padding: 1rem 1.6rem
    span
      cursor: pointer
      font-size: 4.2rem
    .btnsWrapper
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      .icon
        width: 37px
        height: 37px
        margin: 0 5px
        cursor: pointer
    .titleNumbers
      .title
        font-size: 1.4rem
        font-weight: 900
      .numbers
        font-size: 1.6rem

  &:hover
    .targetedUsers
      transform: translateY(50%)
.tooltip
  display: flex
  flex-direction: column
  .tooltipTitle
    font-size: 12px
    font-weight: 900
    line-height: 1.42
    color: #ffffff
  .tooltipDescription
    font-size: 10px
    line-height: 1.7
    color: #ffffff
.tooltipWrapper
  overflow: visible !important
  background-color: #000 !important
  z-index: 10000 !important
  display: flex

.main
    display: flex
    flex-direction: column
    width: 282px
    background-color: #fff
    border: 1px solid #E5E5EA
    border-radius: 8px
    overflow: scroll
    height: fit-content
    max-height: 100%
    overflow-y: auto
    overflow-x: hidden
    margin-right: 16px
    .branchCollapseHeader
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 16px 12px
    .tasksWrapper
        display: flex
        flex-direction: column
    .branchNameWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 12px 24px
        background-color: #fff
        .checkboxWrapper
            display: flex
            justify-content: center
            align-items: center
            width: 16px
            height: 16px
            margin-right: 8px
            border: 1px solid #8E8E93
            box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
            border-radius: 4px
        .branchDetailsWrapper
            display: flex
            flex-direction: column
            .branchStatus
                font-weight: 400
                font-size: 12px
                color: #65656C
            .branchName
                font-weight: 600
                font-size: 14px
                color: #65656C

    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 22.48px 12.48px
        border-bottom: 1px solid #E5E5EA
        .headerIcon
            width: 31.03px
            height: 31.03px
            background: #EAE8F3
            border-radius: 50%
            display: flex
            justify-content: center
            align-items: center
            margin-right: 8.49px
            .storeIcon
                width: 19.39px
                height: 19.39px
        .headerTitle
            font-weight: 400
            font-size: 20px
            color: #1C1C1C
.main::-webkit-scrollbar
    width: 0

@import ../../sass/colors


.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 7rem 0 5rem
  width: 100%
  .title
    font-size: 2.2rem
  .fieldsWrapper
    margin: 2rem 0
    width: 95%
    display: flex
    justify-content: space-between
    align-items: center

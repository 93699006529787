.wrapper
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  width: 100%
  padding: 2rem 2.4rem
  &:nth-of-type(odd)
    background-color: #ffffff
    border-radius: 1rem
  &:nth-child(even)
    border-radius: 1rem
    background-color: #fafafa
  span 
    font-size: 1.8rem
    font-weight: 500
    color: #222222
    margin: 0 2.4rem

  .controllers
    display: flex
    justify-content: center
    align-items: center
    opacity: 0
    .deleteWrapper
      transform: rotate(-360deg)
      border-radius: 4rem
      background-color: rgba(219, 48, 48,0.2)
      width: 4.4rem
      height: 4.4rem
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      .deleteIcon
          width: 2.4rem
          height: 2.4rem
          z-index: 22
          object-fit: contain
    .editWrapper
      transform: rotate(-360deg)
      border-radius: 4rem
      background-color: rgba(65, 18, 108,0.2)
      width: 4.4rem
      height: 4.4rem
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      margin: 0 1.6rem
      cursor: pointer
      .editIcon
          width: 2.4rem
          height: 2.4rem
          z-index: 22
          object-fit: contain
  &:hover
    .controllers
        opacity: 1

.wrapper
    height: 100%
    display: flex
    width: 100%
    flex-direction: column
    padding: 24px
    border: solid 1px #e5e5ea
    background-color: #fff
    .type
        font-size: 24px
        font-weight: bold
        color: #1c1c1e
        margin-top: 22.5px
    .header
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        width: 100%
        .title
            font-size: 20px
            font-weight: bold
            color: #1c1c1e
        .buttonWrapper
            cursor: pointer
            width: 40px
            height: 40px
            display: flex
            justify-content: center
            align-items: center
            padding: 8px
            border-radius: 120px
            background-color: #eae8f3
            .icon
                width: 24px
                height: 24px

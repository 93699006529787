.wrapper
    display: flex
    flex-direction: row
    align-items: center
    .inputWrapper
        display: flex
        flex-direction: column
        .fileName
            font-size: 16px
            font-weight: 700
        .input,.disabled
            outline: none
            border: none
            height: 40px
            padding: 11px 32px
            border-radius: 4px
            background-color: #f09440
            font-size: 1.4rem
            font-weight: 700
            cursor: pointer
            text-align: center
            color: #ffffff
            display: flex
            justify-content: center
            align-items: center
            margin-bottom: 0
        .disabled
            cursor: not-allowed
            background-color: #cccccc
            color: #666666

.downloadTemplate
    color: #f09440
    background: #F2F2F7
    border-radius: 4px
    display: flex
    justify-content: center
    align-items: center
    padding: 10px 12px
    height: 40px
    font-weight: bold
    font-size: 14px
    color: #1C1C1E
    margin: 0 16px
    cursor: pointer
    &:hover
        text-decoration: none
        color: #1C1C1E
    .downloadIcon
        margin-right: 8px
        width: 16px
        height: 16px
.error
    color: #db3030
    font-size: 1.6rem
.brandsNotFoundWrapper
    border-radius: 5px
    padding: 1.6rem
    display: flex
    flex-direction: column
    background-color: #db3030
    .brandsNotFoundNamesWrapper
        display: flex
        flex-direction: row
        flex-wrap: wrap
        .brandsNotFoundNames
            margin-right: 1px
            font-size: 1.2rem
            color: #fff

    .brandsNotFoundHeader
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: row
        .brandsNotFoundHeaderTitle
            color: #fff
            font-size: 1.6rem
        .closeIcon
            cursor: pointer

.main
    display: flex
    flex-direction: column
    background: #FFFFFF
    overflow: hidden
    border: 1px solid #E5E5EA
    border-radius: 8px
    width: 100%
    max-width: 678px
    .loadingContainer
        width: 100%
        height: 100%
        min-height: 500px
        display: flex
        justify-content: center
        align-items: center
    .emptyContainer
        height: 100%
        // max-height: 348px
        width: 100%
        padding-top: 24px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding-top: 88px
        padding-bottom: 132px
        .emptyIcon
            width: 88px
            height: 88px
        .visitDashboardBtn
            text-decoration: none
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            padding: 8px 12px
            width: 172px
            height: 40px
            background: #F9F9FB
            border-radius: 8px
            outline: none
            border: none
            .chevroRight
                width: 24px
                height: 24px
            .visitDashboardText
                font-weight: 700
                font-size: 16px
                color: #1C1C1C
        .emptyDescription
            font-weight: 400
            font-size: 16px
            text-align: center
            margin: 24px 0
            padding: 0 139.5px
            color: #1C1C1C
    .contentWrapper
        height: 100%
        min-height: 500px
        width: 100%
        background-color: #fff
        margin-top: 24px
        overflow-y: scroll
        
    .contentWrapper::-webkit-scrollbar
        display: none
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 19px 24px 19px 48px
        border-bottom: 1px solid #E5E5EA
        .titleWrapper
            display: flex
            flex-direction: row
            align-items: center
            .checkboxWrapper
                display: flex
                justify-content: center
                align-items: center
                cursor: pointer
                width: 16px
                height: 16px
                border: 1px solid #8E8E93
                margin-right: 12.5px
                border-radius: 4px
                box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)

            .title
                font-weight: 400
                font-size: 20px
                color: #1C1C1E
                margin-left: 8px
        .bulkActionsBtn
            display: flex
            justify-content: center
            align-items: center
            padding: 8px 12px
            min-width: 119px
            height: 38px
            background: #FFA351
            border-radius: 8px
            outline: none
            font-weight: 700
            font-size: 16px
            line-height: 22px
            color: #FFFFFF

.wrapper
    display: flex
    flex-direction: column
    align-items: center
    padding: 24px
    background: #FFFFFF
    border: 1px solid #E5E5EA
    width: 100%
    .contentWrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        .line
            width: 1px
            height: 52px
            background: #D9D9D9
            margin: 0 32px
        .separator
            margin-right: 48px
            
        .elementWrapper
            display: flex
            flex-direction: column
            .elementTitle, .elementSubtitle
                font-weight: 400
                font-size: 14px
                color: #1C1C1E
            .elementSubtitle   
                font-weight: 700
                font-size: 24px
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        width: 100%
        .title
            font-weight: 700
            font-size: 20px
            line-height: 27px
            color: #1C1C1E
        .btn, .edit
            display: flex
            outline: none
            align-item: center
            justify-content: center
            padding: 16px
            border: 1px solid #503E9D
            border-radius: 8px
            font-weight: 700
            font-size: 18px
            line-height: 24px
            color: #503E9D
            background: #fff
            .icon
                margin-right: 4px
                width: 24px
                height: 24px
        .edit
            width: 40px
            height: 40px
            border: none
            background: #EAE8F3
            border-radius: 120px  
            display: flex
            justify-content: center
            align-items: center      
            .icon
                margin-right: 0
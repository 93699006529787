.main
    padding: 0 24px
    .wrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 12px 8px 12px 16px
        width: 100%
        background: #FDEAEA
        border-radius: 4px
        margin: 16px 0px
        .closingWrapper
            display: flex
            flex-direction: row
            align-items: center
            .showAllErrors
                display: flex
                background: #FDEAEA
                justify-content: center
                align-items: center
                padding: 10px 12px
                height: 40px
                border: 1px solid #EE5253
                border-radius: 56px
                font-weight: bold
                font-size: 14px
                color: #EE5253
                margin-right: 16px
            .closeIcon
                width: 24px
                height: 24px
                cursor: pointer

        .missingMain
            display: flex
            flex-direction: row
            align-items: center

            .missingValuesWrapper
                display: flex
                flex-direction: column
                .missingValuesTitle
                    font-weight: bold
                    font-size: 16px
                    color: #EE5253
                .missingValues
                    font-size: 14px
                    color: #1C1C1E
            .errorIcon
                width: 20.9px
                height: 18.1px
                margin-right: 13.55px

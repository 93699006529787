.wrapper
    display: block
    width: 100%
    padding: 40px 2rem 0px
    background: #e5e5e596
    height: 100%
.titleWrapper
    .title
        font-weight: 700
        font-size: 28px
        line-height: 28px
.sectionTitleWrapper
    margin-top: 3rem
    .sectionTitle

        color: #8E8E93
        font-size: 16px
        text-decoration: underline
        transition: all 0.2s ease-in-out
        &:hover
            cursor: pointer
            text-decoration: none
        img
            width: 14px
.searchBarWrapper
    background: #fff
    display: flex
    flex-direction: row
    justify-content: space-between
    padding: 20px
    margin-top: 15px
    .brandsCountWrapper
        display: flex
        flex-direction: column
        flex: 1
        justify-content: space-around
        .brandsCount
            font-size: 25px !important
            font-weight: 500
            margin: 0
            line-height: 100%
    .searchbar
        flex: 6
        .inputWrapper
            position: relative
            padding: 0
            margin: 0
            .iconWrapper
                position: absolute
                top: 18px
                left: 15px
                z-index: 100
                .icon
                    display: inline-block
                    width: 20px
                    margin-right: 10px

            .selectionList
                position: absolute
                top: 60px
                left: 0
                width: 100%
                background: #fff
                border-radius: 5px
                border: solid 1px #E5E5EA
                max-height: 400px
                overflow-y: scroll
                z-index: 10
                .notFound
                    width: 100%
                    display: flex
                    justify-content: center
                    padding: 30px
                    span
                        color: #8E8E93
                        font-size: 18px
                        font-weight: bold
                .sectionWrapper
                    display: block
                    .title
                        display: block
                        margin: 20px 0px 10px
                        padding: 0px 15px
                        color: #8E8E93
                        font-size: 18px
                        line-height: 18px
                    .itemWrapper
                        display: block
                        // padding: 0px px
                        .item
                            display: flex
                            width: 100%
                            flex-direction: row
                            justify-content: space-between
                            border-bottom: solid 1px #E5E5EA
                            padding: 15px 35px
                            margin-bottom: 5px
                            &:last-child
                                margin-bottom: 0px
                            .itemTitle
                                display: flex
                                align-items: center
                                color: #000
                                font-size: 22px
                                line-height: 25px
                                font-weight: 500

                            .itemAction
                                .loaderWrapper
                                    width: 70px
                                    justify-content: centrer
                                .btn
                                    display: flex
                                    align-items: center
                                    color: #fff
                                    padding: 15px
                                    border-radius: 15px
                                    font-size: 14px
                                    font-weight: 700
                                    padding: 10px 20px
                                    .icon
                                        padding: 0px 5px
                                        font-size: 20px
                                        line-height: 14px
                                        .imageIcon
                                            width: 14px
                                            height: 14px

                                    &.active
                                        background: #FFA351
                                    &.inactive
                                        background: #E5E5EA
                                        &:hover
                                            cursor: not-allowed

.clickable
    transition: all 0.2s ease-in-out
    &:hover
        cursor: pointer
.iconPadding
    padding-left: 70px !important
.greyBg
    background: #F2F2F7 !important
    padding-top: 5px !important
    font-size: 16px !important
    &::placeholder
        font-size: 16px
.inputIcon
    color: black
    font-Weight: bold
    font-size: 23px
    padding-top: 0px
    margin: 0px
    line-height: 10px
    height: auto
    transform: translateY(5px)

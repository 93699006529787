.wrapper, .disabledWrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    border: 1px solid #f09440 !important
    font-weight: bold
    cursor: pointer
    font-size: 16px
.disabledWrapper
    cursor: not-allowed

@import ../../sass/colors


.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 7rem
  p
    font-size: 1.6rem
  .btn
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
    border: none
    width: 16.84rem
    margin: 2rem 0
    height: 5rem
    font-size: 1.8rem
    outline: 0
    cursor: pointer
    border-radius: 4rem
    color: white
    transition: all .2s
    outline: none !important
  .btn:hover
    transform: translateY(-3px)
    box-shadow: $box-shadow-btn-hover
  .btn:active
    box-shadow: $box-shadow-btn-active
    transform: translateY(-1px)

@import ../../sass/colors

.wrapper
  display: flex
  align-items: center
  justify-content: center
  margin: 3rem 0
  .card
    display: flex
    width: 45%
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: white
    padding: 3rem
    margin: 1rem 0
    h1
      margin: 1rem 0
    .datePickerWrapper
      width: 100%
      display: flex
      flex-direction: column
      margin: 1rem 0
      span
        font-size: 1.5rem
        position: relative
        margin: 0
        color: #606977
      .datePicker
        display: flex
        align-items: center
        padding: 0
        position: relative
        width: 100%
        div:first-child
          width: 100%
          div
            bottom: 6rem
          .dateInput
            width: inherit
            height: 6rem
            border-radius: 1rem
            padding: 0 1rem
            font-size: 1.8rem !important
            border: 1px solid $color-border !important
            color: black
    .icon
      position: absolute
      right: 0
      font-size: 2rem
      padding: 0 1rem
    .btn
      background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
      border: none
      width: 16.84rem
      height: 5rem
      font-size: 1.8rem
      outline: 0
      margin: 1rem 0
      cursor: pointer
      border-radius: 4rem
      color: white
      transition: all .2s
      outline: none !important
    .btn:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover
    .btn:active
      box-shadow: $box-shadow-btn-active
      transform: translateY(-1px)
    .inputWrapper
      display: flex
      width: 100%
      margin: 1rem 0
      flex-direction: column
      .label
        position: relative
        margin: 0
        color: #606977
        font-size: 1.5rem
      .input
        height: 15rem
        outline: none
        resize: none
        border-radius: 1rem
        font-size: 1.8rem
        padding: 1rem
        color: $color-black-text
        border: 1px solid $color-border
        text-align: left
    .dropDownPart
      width: 100%
      display: flex
      flex-direction: column
      margin: 1rem 0
      span
        font-size: 1.5rem
        color: #606977
        position: relative
        margin: 0
      .switch
        font-size: 1.8rem !important
        border: 1px solid $color-border !important
        height: 6rem
        margin: 0 !important
        width: 100% !important
        border-radius: 1rem !important
        padding: 0 1rem !important
        outline: none !important
        background-color: white !important
        text-align: left !important
        color: black !important
        box-shadow: none !important

    .menuWrapper
      width: 100%
      .item
        padding: 1rem
        text-align: center
        font-size: 1.8rem
        outline: 0 !important
      .item:hover
        background-color: rgba(254,190,25,.1)

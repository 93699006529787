.wrapper
  display: flex
  justify-content: center
  margin-bottom: 2.5rem
  position: sticky
  top: 0
  flex-direction: column
  .addWrapper
    display: flex
    flex-direction: column
    width: 100%
    height: 14.4rem
    box-shadow: 0 7px 14px 0 rgba(7, 4, 4, 0.1)
    background-color: #fefefe
    .line
      height: .2rem !important
      width: 100%
      background-color: #ebebeb
    .add
      display: flex
      flex-direction: row
      justify-content: space-between
      .title
        font-size: 2.2rem
        padding: 2.9rem 3rem
        color: #000000
      .addUserWrapper
        display: flex
        justify-content: center
        padding: 1.6rem 3.2rem
        .addUser
          width: 16.8rem
          height: 5.6rem
          outline: none
          border-radius: .5rem
          background-color: #41126c
          font-size: 1.4rem
          font-weight: bold
          line-height: 1.29
          letter-spacing: 0.1px
          text-align: center
          color: #ffffff
        .icon
          width: 2rem
          height: 2rem
          object-fit: contain
    .userWrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      padding: 1.4rem
      .basicInfo
        display: flex
        flex: 1.2
        font-size: 1.4rem
        font-weight: 900
        letter-spacing: 0.7px
        color: #9b9b9b
      .email
        display: flex
        flex: 1.2
        font-size: 1.4rem
        font-weight: 900
        letter-spacing: 0.7px
        color: #9b9b9b
      .country
        display: flex
        flex: .8
        font-size: 1.4rem
        font-weight: 900
        letter-spacing: 0.7px
        color: #9b9b9b
      .role
        display: flex
        flex: 1.2
        font-size: 1.4rem
        font-weight: 900
        letter-spacing: 0.7px
        color: #9b9b9b

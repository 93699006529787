.wrapper
  display: flex
  width: 100%
  justify-content: space-between
  align-items: flex-start
  margin-bottom: 3rem
  .collection
    display: flex
    align-items: center
    margin-left: -1.25rem
    .dot
      height: 2.4rem
      width: 2.3rem
      background-color: #febe19
      border-radius: 50%
      display: inline-block
    .date
      font-size: 1.2rem
      margin: 0 1rem
      color: rgba(45,45,45,.4)
  .info
    display: flex
    flex-direction: column
    width: 65%
    .text
      font-size: 1.6rem
      color: black
    .comment
      .adminComment
        color: #C7C7C7
        margin: 1rem 0
        font-size: 1.4rem
      .commentText
        font-size: 1.6rem

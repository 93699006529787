.container
    display: flex
    flex-direction: column
    width: 100%
    padding: 0px 24px
    .promocodeSplitTitle
        font-weight: bold
        font-size: 20px
        margin: 0px 16px
    .splitPromocodeWrapper
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        padding: 16px
        background: #FFFAF5
        border-radius: 4px
        margin: 24px 0px
        .splitAllPromocodeSwitchWrapper
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            .splitAllPromocodeSwitcherTitle
                font-weight: bold
                font-size: 20px
                color: #1C1C1E
        .main
            display: flex
            flex-direction: column
            width: 100%
            .maskedPromocodeWrapper
                display: flex
                flex-direction: column
                padding: 16px
                width: 100%
                background: #F2F2F7
                border-radius: 4px
                margin: 16px 0px
                .maskedPromocodeInputsWrapper
                    display: flex
                    flex-direction: row
                .maskedPromocodeTitle
                    font-weight: bold
                    font-size: 20px
                    margin-bottom: 16px
                    color: #1C1C1E
            .wrapper
                display: flex
                flex-direction: row

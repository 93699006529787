
.wrapper
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  margin-bottom: 1.5rem
  .addBtn, .addDimmedBtn
    width: 11.6rem
    height: 4.8rem
    border-radius: 4px
    font-size: 1.6rem
    font-weight: bold
    line-height: 1.13
    letter-spacing: 0.11px
    text-align: center
    margin-left: 0.8rem !important
  .dropDownPart
    width: 100%
    display: flex
    flex-direction: column
    margin: 0
    span
      font-size: 16px !important
      font-weight: bold
      color: #000000 !important
      position: relative
      margin: 0
      margin-bottom: 1rem !important
    .switch
      font-size: 1.6rem !important
      margin: 0 !important
      width: 100%
      border-radius: 5px !important
      outline: none !important
      background-color: white !important
      text-align: left !important
      font-weight: 500 !important
      color: #222222 !important
      box-shadow: none !important
      height: 5.5rem
      box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
      border: solid 1px #a6a6a6
      background-color: #ffffff
      padding: 0 1.6rem
      span 
        font-size: 1.6rem
        color: #1a1a1a
      svg 
        width: 2.5rem
        height: 2.5rem
      div
        display: flex !important
        align-items: center !important
        justify-content: space-between !important

    .switch:disabled
      background-color: #F3F3F3 !important
      cursor: default
  .addDimmedBtn
    cursor: not-allowed    

  .menuWrapper
    max-height: 30rem
    overflow: auto
    min-width: 100% !important
.item
  padding: 1rem
  text-align: center
  font-size: 1.8rem
  outline: 0 !important

.chipsWrapper
  display: flex
  flex-wrap: wrap



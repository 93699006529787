.wrapper
    display: flex
    flex-direction: column
    width: 100%
    .label
        margin-bottom: 10px
        span
            font-weight: 600
            font-size: 16px
            line-height: 22px
            color: #2D2D2D
    .inputAndLoadingWrapper
        display: flex
        flex-direction: row
        align-items: center
        background: #FFFFFF
        border: 1px solid #C7C7CC
        box-sizing: border-box
        width: 100%
        height: 100%
        border-radius: 4px
        .postfixWrapper
            background: #D1D1D6
            border-radius: 0px 5px 5px 0px
            display: flex
            align-items: center
            justify-content: center
            height: 100%
            padding: 17px 24px
            .postFix
                font-weight: 800
                font-size: 16px
                color: #1C1C1E
        .input
            width: 100%
            height: 100%
            background: #fff
            outline: none
            border: none
            padding: 17px 16px
            font-size: 16px
            color: #000
        .input::placeholder
            font-size: 16px
            color: #8E8E93

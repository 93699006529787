.wrapper
  display: flex
  width: 100%
  flex-direction: column
  padding-top: 4.1rem
  padding-left: 3rem
  padding-right: 3.1rem
  .totalTagsWrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    .totalTags
      font-size: 2.2rem
      color: #000000
    .btn
      width: 17.2rem
      height: 5.6rem
      border-radius: 5px
      background-color: #41126c
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      color: #ffffff
      outline: none
    .icon
      width: 2rem
      height: 2rem

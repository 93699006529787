.cardTitle
    font-size: 24px !important
    font-weight: bold !important
    color: #1c1c1e !important
.active, .inActive, .inProgress, .closed, .churned
  color: #7ED321
  font-weight: bold
  margin: 0
  font-size: 24px
  .dot
    margin: 0
  .dot:before
    margin: 0 .6rem
    content: '\25CF'
    font-size: 1.6rem

.active
  .dot:before
    color: #7ED321
.inActive
  color: #FEBE19
  .dot:before
    color: #FEBE19

.inProgress
  color: deepskyblue
  .dot:before
    color: deepskyblue

.closed
  color: #65656c
  .dot:before
    color: #65656c
.churned
  color: #ee5253
  .dot:before
    color: #ee5253
@import './form-ui'

.form 
  width: 51.2rem
  padding: 12rem 3rem
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .wrapper
    width: 100%    
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
  @include form-shared



.headerIconText
  color:  #1C1C1C
  font-size: 16px
  font-weight: 600
  width: 100%
  margin-bottom: 12px
.headerIcon
  display: flex
  width: 320px !important
  padding: 55px 24px
  flex-direction: column
  align-items: flex-start
  gap: 8px
.recommendedSizeWrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%
  width: 100%
  background-color: #f9f9f9
  gap: 12px
  .recommendedSizeText, .recommendedSizeSubText
    font-size: 16px
    font-weight: 400
    color: #1C1C1C
  .recommendedSizeSubText
    font-size: 14px
    color: #8E8E93  
.headerIconWrapper
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
.dropzone
    background-color: #F9F9FB !important
    width: 320px !important
    height: 167px !important
.headerIconPreview    
  width: 320px !important
  height: 167px !important
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
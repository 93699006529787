.roundWrapper
  background-color: rgba(0,0,0,0.3)
  min-height: 100vh !important
  overflow-y: hidden
  display: flex
  align-items: center
  justify-content: center
  overflow: auto

.pincodeWrapper
  width: 100vw
  overflow-y: hidden
  max-width: 56.1rem
  border-radius: 2rem
  background-color: #ffffff
  .closeWrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    padding: 2rem 2.5rem 1rem
    img
      width: 16px
      height: auto
      &:hover
        cursor: pointer
    .title
      font-size: 2rem
      font-weight: 500
      color: #1a1a1a
      text-align: left

  img
    width: 12.4rem
    height: 5.1rem
  .loginPage
    width: 100%

  .form
    background: #FFFFFF
    text-align: center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%

  .form
    input
      height: 5.7rem
      width: 100%
      object-fit: contain
      border-radius: 5px
      background-color: #ffffff
      padding: 0 1.6rem
      font-family: LucidaGrande, 'Comfortaa', 'A Jannat LT', sans-serif
      font-size: 1.6rem
      &:placeholder
        color: #a6a6a6
    label
      font-size: 16px
      display: block
      font-weight: normal
      text-align: left
  .errorNotification
    margin-top: 3rem
    margin-bottom: 2rem
    border-radius: 5px
    padding: 1.4rem 1.6rem
    position: relative
    background-color: #db3030
    box-shadow: none
    font-size: 1.6rem
    height: 5.2rem
    img
      width: 2rem
.usernameWrapper
  display: flex
  flex: 1
  width: 65%
  flex-direction: row
  padding: 1.5rem 3.6rem 1rem
  h2
    margin-top: 20px
.footer
  width: 100%
  border-radius: 0 0 1rem 1rem
  background-color: #f2f2f2
  display: flex
  flex-direction: row
  justify-content: flex-end
  padding: 1.5rem 2.4rem
  align-items: center

  img
    margin-top: 0
    width: 2rem
    height: 2rem
.wrapper
  display: flex
  justify-content: center
  align-self: center
  align-items: center
  align-content: center
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
.editReasonBtn, .Btn, .disabled,.deleteBtn
  display: flex
  align-items: center
  justify-content: center
  width: 15.7rem
  height: 4.8rem
  border-radius: 5px
  background-color: #ee8728
  font-size: 1.4rem
  font-weight: bold
  line-height: 1.29
  letter-spacing: 0.1px
  text-align: center
  color: #ffffff
  outline: none
.disabled
  cursor: not-allowed
  background-color: #a6a6a6

.inputWrapper
  width: 100%
  margin-bottom: 1.5rem
  justify-content: flex-start

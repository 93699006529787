.tableWrapper {
  display: block;
}
.tableWrapper .tableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding: 20px;
  border: solid 1px #F2F2F7;
}
.tableWrapper .tableHeader {
  color: #8E8E93;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 16px;
}
.tableWrapper .brandInfo {
  display: flex;
  flex: 3;
}
.tableWrapper .brandInfo .image {
  justify-content: center;
  width: 45px;
  height: 45px;
  box-shadow: 0 0 1px 1px #fff4ea;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
}
.tableWrapper .brandInfo .image img {
  width: 45px;
  height: 45px;
}
.tableWrapper .brandInfo .content {
  padding: 0px 15px;
}
.tableWrapper .brandInfo .content .brandName .country {
  display: block;
  margin: 0;
  padding: 0;
}
.tableWrapper .brandInfo .content .brandName {
  font-weight: 700;
  font-size: 16px;
}
.tableWrapper .brandInfo .content .country {
  font-size: 12px;
  color: #000;
}
.tableWrapper .actions {
  flex: 2;
}
.tableWrapper .actions .btn {
  display: inline-block;
  margin-right: 5px;
  margin-right: 10px;
  background: none;
}
.tableWrapper .actions .btn:active {
  outline: none;
}
.tableWrapper .actions .btn img {
  width: 45px;
  height: 45px;
}
.tableWrapper .dateAdded {
  flex: 9;
  font-size: 16px;
  color: #000;
}

.loaderWrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.tableNotFoundWrapper {
  display: flex;
  width: 100%;
  height: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.tableNotFoundWrapper img {
  width: 82px;
  height: 81.9px;
  margin-bottom: 12.1px;
}
.tableNotFoundWrapper span {
  font-size: 16px;
  font-weight: bold;
  color: #1c1c1c;
}
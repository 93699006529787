.wrapper
    display: flex
    flex-direction: column
    justify-content: center
    padding: 32px 100px

    .posContainer
        display: flex
        flex-direction: column
        background-color: #fff
        width: 772px
        min-height: 400px
        border: 1px solid #E5E5EA
        border-radius: 16px
        box-shadow: 0px 4px 8px 0px rgba(142, 142, 147, 0.12)
        .content
            min-height: 400px
            display: flex
            flex-direction: column
            padding: 24px
            gap: 24px
            .addNewPos, .noBrandPosBtn
                display: flex
                flex-direction: row
                align-items: center
                padding: 8px 12px
                border-radius: 8px
                border: 1px solid #1C1C1C
                color: #1C1C1C
                font-size: 16px
                font-weight: 700
                background-color: transparent
                outline: none
                width: fit-content
                .addIcon
                    width: 24px
                    height: 24px
            .noBrandPosBtn
                background-color: #FF9000
                color: #FFF
                border: none
                align-self: center
            .noPosWrapper
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                .infoIcon
                    margin-top: 76
                    width: 64px
                    height: 64px
                .noPosText
                    font-size: 18px
                    font-weight: 400
                    color: #1C1C1C
                    margin: 24px 0
                    text-align: center
            .loadingWrapper
                height: 400px
                display: flex
                justify-content: center
                align-items: center
            .posIntegrationText
                color: #333
                font-size: 20px
                font-weight: 700

        .brandInfo
            display: flex
            flex-direction: row
            align-items: center
            border-bottom: 2px solid #F2F2F7
            padding: 24px
            .brandLogo
                width: 62.359px
                height: 62.359px
                border-radius: 62.359px
                margin-right: 16.82px
                object-fit: cover
            .brandTexts
                display: flex
                flex-direction: row
                align-items: center
                .brandAddress
                    color: #65656C
                    font-size: 14px
                    font-weight: 400
                .brandName
                    color: #1C1C1C
                    font-size: 20px
                    font-weight: 700
    .backWrapper
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: 24px
        outline: none
        border: none
        background-color: transparent
        .backText
            color: #1C1C1C
            font-size: 18px
            font-weight: 700
            margin-left: 12px

.main
  display: flex
  justify-content: center
  align-items: center
  .wrapper
    width: 70rem
    border-radius: 1rem
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
    background-color: #ffffff
    display: flex
    flex-direction: column
    .btnWrapper
      height: 8rem
      border-radius: 0 0 1rem 1rem
      display: flex
      justify-content: flex-end
      align-items: center
      flex-direction: row
      padding: 1.6rem 2.4rem
      background-color: #f2f2f2
      .cancel
        margin: 0 1rem
        width: 15.7rem
        height: 4.8rem
        border-radius: 4px
        border: solid 1px #ee8728
        background-color: #f2f2f2
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        color: #ee8728
      .save
        width: 15.7rem
        height: 4.8rem
        border-radius: 5px
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        color: #ffffff
        background-color: #f09440
        &:active, &:focus
          border: none !important
          outline: none !important
    .separator
      margin: 0 2rem
    .form
      padding: 1rem 3.2rem 4rem 3.2rem
      display: flex
      flex-direction: row
      align-items: flex-start
      justify-content: center

    .title
      padding: 3.2rem 3.2rem 2.2rem 3.2rem
      font-size: 2.2rem
      font-weight: 500
      color: #2d2d2d
.select
  flex: 1,

.flex1
  width: 37%
  margin-top: 0

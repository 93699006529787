.wrapper
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
  padding: 24px
  border: solid 1px #e5e5ea
  background-color: #fff
  // margin: 0 24px
  .labelWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width: 100%
    .label
      font-size: 20px
      font-weight: bold
      text-align: left
      color: #1c1c1e
    .iconWrapper
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 120px
      background-color: #eae8f3
      outline: none
      border: none
      .icon
        width: 24px
        height: 24px

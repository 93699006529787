
.wrapper
  min-width: 26.8rem
  height: 33.3rem
  border-radius: 12px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #bfbfbf
  object-fit: contain
  display: flex
  justify-content: center
  align-items: center
  margin: 3rem
  position: relative
  .bannerImage
    position: absolute
    width: 100%
    height: 100%
    border-radius: 13px
    z-index: 1
    object-fit: cover
  .targetedUsers
    transition: all ease-in-out .2s
    position: absolute
    width: 100%
    opacity: 0
    bottom: 0
    height: 7.4rem
    background-color: #ffffff
    border-radius: 13px
    display: flex
    align-items: flex-end
    justify-content: space-between
    padding: 1rem 1.6rem
    span
      cursor: pointer
      font-size: 4.2rem
    .titleNumbers
      .title
        font-size: 1.4rem
        font-weight: 900
      .numbers
        font-size: 1.6rem

  &:hover
    .targetedUsers
      z-index: 2
      opacity: 1


.info
  width: 100% !important
  background-color: #ffffff !important
  display: flex !important 
  justify-content: space-between !important
  flex-direction: row !important
  .btnWrapperHide
    display: none
  .btnWrapper
    display: flex
    justify-content: center
    flex-direction: row
    align-self: center
    flex: 1
    .edit
      width: 4.4rem
      height: 4.4rem
      border-radius: 40px
      background-color: rgba(65,18,108, 0.2)
    .delete, .disabledDelete
      width: 4.4rem
      height: 4.4rem
      border-radius: 40px
      background-color: rgba(219,48,48, 0.2)
    .disabledDelete 
      cursor: not-allowed
      opacity: 0.5
  .userInfo
    display: flex    
    flex: 2 !important
  .userInfoActive,.userInfo
    display: flex
    flex: 2
    padding: 1rem
    .user
      display: flex
      margin-left: 1rem
      align-self: center
      font-size: 1.6rem
      font-weight: 900
      color: #2d2d2d
    .avatar
      display: flex
      align-items: center
      justify-content: center
      width: 5.6rem
      height: 5.5rem
      border-radius: 43.5px
      background-color: #f09440  
      .words
        font-size: 1.9rem
        font-weight: 500
        line-height: 1.68
        letter-spacing: 0.4px
        color: #ffffff
  .email
    display: flex
    flex: 2 !important
  .emailActive, .email
    font-size: 1.6rem
    font-weight: 500
    flex: 2
    display: flex
    padding: 2rem 0 
    align-self: center
    line-height: 1.13
    letter-spacing: 0.3px
    color: #2d2d2d   
  .country  
    display: flex
    flex: 1.4 !important
  .countryActive, .country   
    display: flex
    padding: 2rem 0 
    align-self: center
    flex: 1.4
    font-size: 1.6rem
    font-weight: 900
    line-height: 1.13
    letter-spacing: 0.3px
    color: #2d2d2d
  .role
    display: flex
    flex: 2  !important
  .roleActive,.role
    display: flex
    flex: 1
    align-self: center
    padding: 2rem 0 !important
    .roleWrapper
      display: flex !important
      justify-content: center !important
      align-self: center !important
      text-align: center
      width: 9.4rem !important
      height: 3.2rem !important
      border-radius: 15.5px
      background-color: rgba(65,18,108, 0.2) !important
      .roleTitle
        font-size: 1.4rem
        font-weight: 900
        line-height: 1.29
        letter-spacing: 0.4px
        color: #41126c
        display: flex
        align-self: center
  
    

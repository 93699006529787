.wrapper
  display: flex
  align-items: center
  .downloadTemplate
    background: #F2F2F7
    border-radius: 4px
    color: #1C1C1E
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 10px 12px
    height: 40px
    font-size: 14px
    font-weight: bold
    margin: 0 16px
    cursor: pointer
    &:hover
      text-decoration: none
      color: #1C1C1E
    .downloadIcon
      margin-right: 8px
      width: 16px
      height: 16px
  .uploadButton, .dimmedButton
    cursor: pointer
    border-radius: 4px
    background-color: #f09440
    display: flex
    justify-content: center
    align-items: center
    font-size: 14px
    font-weight: bold
    color: #ffffff
    padding: 10px 12px
    height: 40px
    margin-bottom: 0
  .dimmedButton
    cursor: not-allowed
    background-color: #d2d2d2
.fileName
  font-size: 1.2rem
  font-weight: 500
  color: #1a1a1a

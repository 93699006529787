.dropDownPart
  max-width: 100%
  display: flex
  flex-direction: column
  margin: 1rem 0
  margin-bottom: 2.4rem
  .label
    font-size: 16px
    font-weight: 600
    color: #1c1c1e
    margin-bottom: 8px
  .switch
    height: 56px
    width: 100%
    align-self: stretch
    flex-grow: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 8px 16px
    border-radius: 4px
    background-color: #f2f2f7!important
    cursor: default
    outline: none
    border: none !important
    box-shadow: none !important
    &:active, &:focus, &:hover
      border: none !important
      box-shadow: none !important
      background-color: #f2f2f7 !important
    .labelWithValue
      font-size: 14px
      color: #000
      margin-right: 8px
    .dropdownIcon
      width: 24px
      height: 24px
    .selectedItem
      font-size: 16px
      color: #1c1c1e

.menuWrapper
  border: 1px solid #f2f2f7 !important
  border-radius: 4px
  outline: none
  width: 100%
  margin-top: 4px
  &:focus, &:hover, &:active
    background-color: #fff !important
    color: #000 !important
.dropdownItem
  padding: 8px 16px
  font-size: 14px
  color: #000
  &:active, &:focus
    outline: none
    box-shadow: none
    background-color: #f8f9fa !important
    color: #000 !important

.input
    width: 100%
    height: 55px
    border-radius: 0.5rem
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
    border: solid 1px #a6a6a6
    background-color: #ffffff
    padding: 0 1.6rem
    outline: none
    font-size: 1.8rem
    font-weight: 500
    color: #000000

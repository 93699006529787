.wrapper
  display: flex
  flex-direction: column
  align-items: center
  width: 51.2rem

  // padding: 12.5rem 0
  .headerWrapper
    display: flex
    justify-content: space-between
    width: 100%
    flex-direction: row
    padding: 12.5rem 3.2rem 0 3.2rem
    .title
      font-size: 2.8rem
      font-weight: 900
      color: #1a1a1a
    .closeIcon
      display: flex
      align-self: center
      width: 19px
      height: 19px
  .formWrapper
    width: 51.2rem
    // border-radius: 0.5rem
    // box-shadow: 0 1.2rem 2.4rem 0 rgba(31, 4, 48, 0.1)
    // background-color: #ffffff
    form
      width: 100%
      padding: 3.2rem
      .separator
        margin: 2.4rem 0
      .fileWrapper
        display: flex
        // justify-content: space-between
        align-items: center
        .fileLab
          margin: 0 16px
          text-decoration: underline
          font-size: 1.6rem
          font-weight: 500
          color: #f09440
          padding-top: 2.5rem
    .submitBtn
      width: 100%
      height: 5.6rem
      border-radius: 0.4rem
      background-color: #f09440
      font-size: 1.8rem
      font-weight: bold
      letter-spacing: 0.13px
      text-align: center
      outline: 0
      color: #ffffff
      margin-top: 5.2rem
      margin-bottom: 3.2rem
    .datePicker
      label > span
        font-weight: 800
        font-size: 1.6rem !important
        margin-bottom: 1.6rem

.fileName
  font-size: 1.2rem
  font-weight: 500
  color: #1a1a1as

.wrapper
    display: flex
    flex-direction: column
    padding: 0px 24px
    .pocsWrapper
        display: flex
        flex-direction: column
        .pocDetailsWrapper
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            margin-bottom: 24px
            .updateAt
                font-style: italic
                font-weight: 400
                font-size: 14px
                color: #8E8E93
            .pocContentWrapper
                display: flex
                flex-direction: column

                .pocNameAndTitle
                    display: flex
                    flex-direction: row
                    align-items: center
                    .copyIcon
                        cursor: pointer
                        width: 16px
                        height: 16px
                        margin-right: 8px
                    .separator
                        background-color: #C7C7CC
                        width: 1px
                        height: 19px
                        margin: 0 6px
                    .pocName
                        font-weight: 400
                        font-size: 14px
                        color: #1C1C1C
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding-bottom: 24px
        .ic_refresh
            cursor: pointer
            width: 20px
            height: 20px
        .headerContact
            display: flex
            flex-direction: row
            align-items: center
            .title
                font-weight: 700
                font-size: 16px
                color: #65656C
                margin-right: 12px
            .updatePocBtn
                text-decoration: none
                cursor: pointer 
                padding: 8px 12px
                width: 79px
                height: 38px
                background: #F9F9FB
                border-radius: 8px
                font-weight: 700
                font-size: 16px
                color: #1C1C1C

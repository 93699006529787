.wrapper
  width: 100%
  .CategoriesWrapper
    margin: auto
    width: 87.1rem
    display: flex
    flex-direction: column
    align-items: center
    border: solid 1px #bfbfbf
    background-color: #fefefe
    border-radius: 1rem

.header
  font-size: 2.2rem
  font-weight: 500
  color: #2d2d2d

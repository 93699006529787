.main
    cursor: pointer
    padding: 8px 0 8px 0
    display: flex
    flex-direction: row
    align-items: center
    background-color: #fff
    box-shadow: inset 0 -1px 0 0 #f2f2f7
    border-radius: 5px

    .brandNameWrapper
        display: flex
        flex-direction: row
        align-items: center
        flex: 1.6
        padding-left: 36px
        .brandIconWrapper
            width: 40px !important
            height: 40px !important
            box-shadow: 0 0 1px 1px #fff4ea
            border-radius: 50%
            margin-right: 8px
            .brandIcon
                width: 100%
                height: 100%
                border-radius: 50%
        .brandWrapper
            display: flex
            flex-direction: column
            .brandName
                font-size: 14px
                color: #1c1c1e
            .country
                font-size: 14px
                color: #65656c
    .totalCustomers
        flex: 1
        font-size: 14px
        color: #1c1c1e
    .setup, .waitingForPayment, .paid, .trial
        font-size: 14px
        color: red
        font-weight: bold
        flex: 1.02
    .paid
        color: #38c172
    .trial
        color: deepskyblue
    .setup
        color: #ccc

    .activeDealsType
        flex: 1.3
        .activeDealsIco
            width: 32px
            height: 32px
            margin-right: 8px
    .activeStatusWrapper, .inactiveStatusWrapper, .inProgressStatusWrapper, .closedStatusWrapper, .churnedStatusWrapper

        flex: 1.2
        display: flex
        flex-direction: row
        align-items: center
        .dot
            width: 12px
            height: 12px
            border-radius: 56px
            background-color: #38c172
        .running
            font-size: 14px
            font-weight: bold
            color: #38c172
            margin-left: 8px
    .inactiveStatusWrapper
        .dot
            background-color: #f4c247
        .running
            color: #f4c247
    .inProgressStatusWrapper
        .dot
            background-color: #55bcf9
        .running
            color: #55bcf9
    .closedStatusWrapper
        .dot
            background-color: #65656c
        .running
            color: #65656c


    .churnedStatusWrapper
        .dot
            background-color: #ee5253
        .running
            color: #ee5253            
    .actionsWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding-right: 36px
        .editButton
            width: 40px
            height: 40px
            display: flex
            border-radius: 120px
            background-color: #eae8f3
            justify-content: center
            align-items: center
            outline: none
            margin-left: 12px
            .editIcon
                width: 19.8px
                height: 19.8px

        .buttonWrapper
            display: flex
            flex-direction: row
            align-items: center
            padding: 10px 12px
            border-radius: 4px
            border: solid 1px #503e9d
            background-color: #fff
            text-decoration: none
            cursor: pointer
            .buttonIcon
                width: 16px
                height: 16px
                margin-right: 8px
            .buttonText
                font-size: 14px
                font-weight: bold
                color: #503e9d

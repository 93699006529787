.wrapper
  display: flex
  flex-direction: row
  justify-content: space-around
  padding: 2.1rem 3.6rem
  border-bottom: 1px solid #e6e6e6
  .statusWrapper
    position: relative
    left: 1.4rem
    flex: 2.4
    display: flex
    flex-direction: column
    justify-content: center
    .statusTitle
      font-size: 1.4rem
      font-weight: 500
      line-height: 1.29
      letter-spacing: 0.26px
      color: #2d2d2d
    .pendingWrapper
      height: 2.6rem
      width: 15rem
      border-radius: .6rem
      display: flex
      justify-content: center
      align-items: center
      background-color: rgba(253, 187, 17, 0.12)
      .pendingTitle
        margin-top: .1rem
        font-size: 1.6rem
        font-weight: 900
        line-height: 1.13
        letter-spacing: 0.3px
        color: rgb(253, 187, 17)
    .completedWrapper, .runningWrapper, .scheduledWrapper, .failureWrapper
      width: 9.9rem
      height: 2.6rem
      border-radius: .6rem
      display: flex
      justify-content: center
      align-items: center
      background-color: rgba(56, 193, 114, 0.12)
      .completedTitle, .runningTitle, .scheduledTitle, .failureTitle , .pendingTitle
        margin-top: .1rem
        font-size: 1.6rem
        font-weight: 900
        line-height: 1.13
        letter-spacing: 0.3px
        color: #38c172
      .runningTitle
        color: #fdbb11
      .scheduledTitle
        color: #1a1a1a
      .failureTitle
        color: #a14949
    .runningWrapper
      width: 7.2rem
      background-color: rgba(253, 187, 17, 0.12)
    .scheduledWrapper
      width: 8.9rem
      background-color: rgba(26, 26, 26, 0.12)
    .failureWrapper
      background-color: rgba(252, 0, 0, 0.12)
  .bodyTextWrapper
    flex: 3
    display: flex
    flex-direction: column
    justify-content: center
    position: relative
    left: 2.2rem
    .bodyText

      font-size: 1.6rem
      font-weight: 900
      line-height: 1.13
      letter-spacing: 0.3px
      color: #2d2d2d
    .bodySubText
      max-width: 28.9rem
      margin-top: .5rem
      font-size: 1.4rem
      font-weight: 500
      line-height: 1.29
      letter-spacing: 0.26px
      color: #2d2d2d
  .dateWrapper, .successRate, .openRate
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    .dateHeader
      font-size: 1.6rem
      font-weight: 900
      line-height: 1.13
      letter-spacing: 0.3px
      color: #2d2d2d
    .dateTitle
      margin-top: .5rem
      font-size: 1.4rem
      font-weight: 500
      line-height: 1.29
      letter-spacing: 0.26px
      color: #2d2d2d
  .openRate
    flex: 2.5
  .successRate
    flex: 1.8

.scheduledDateWrapper
  font-size: 1.4rem
  font-weight: 500
  line-height: 1.29
  letter-spacing: 0.26px
  color: #2d2d2d
.per
  font-size: 1.4rem
  font-weight: 500
  line-height: 1.29
  letter-spacing: 0.26px
  color: #2d2d2d

@import ../../sass/colors

.wrapper
  display: flex
  flex-direction: column
  height: 100%
  span
    margin: 0
    padding: 0
  .rejectedText
    margin: auto
  .coverContainer
    width: 100%
    height: 20rem
    display: flex
    background: linear-gradient(rgba(0, 0, 0, .65) 8.69%, rgba(128, 128, 128, .32) 100%)
    .cover
      width: 100%
      height: 20rem
      opacity: .6
      object-fit: cover
      object-position: center
      flex: 1
  .profile
    display: flex
    align-items: center
    justify-content: space-around
    margin-top: -9rem
    z-index: 999
    .goToDashboard
      width: 25%
      margin-bottom: 2rem
      margin-right: 2rem
      display: flex
      outline: none
      justify-content: center
      align-items: center
      padding: 0 3.2rem
      height: 4.8rem
      border-radius: 4px
      color: #41126c
      font-size: 1.6rem
      font-weight: bold
      background-color: #ffffff
      text-decoration: none
      cursor: pointer
    .profileData
      display: flex
      align-items: center
      width: 100%
      .brandImg
        height: 12rem
        width: 12rem
        object-fit: cover
        z-index: 999
        border-radius: 50%
        margin: 0 2rem
        background-color: white
        padding: .3rem
      .brandName
        font-size: 1.4rem
        margin: 0 .5rem
        p
          margin: -.2rem 0 0 0
          color: $color-grey-paragraph
          font-weight: 300
    .active
      color: #7ED321
      margin-top: -1.5rem
      font-weight: 600
      font-size: 1.4rem
      .activeDot:before
        margin-right: -.8rem
        content: ' \25CF'
        font-size: 1.6rem

  .postProfileWrapper
    width: 100%
    margin-top: -3rem
    display: flex
    justify-content: flex-end
    background-color: white
    .postProfile
      display: flex
      justify-content: space-between
      align-items: center
      width: 90%
      padding: 1rem 2rem
      .firstPart
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .account_manager
          display: flex
          align-items: center
          justify-content: center
          width: 100%
          .select
            flex: 4
          .label
            flex: 1
            margin-top: 10px
            font-size: 16px
            font-weight: 700
            padding: 0px 10px 0px 20px

        .info
          display: flex
          justify-content: space-around
          align-items: center
          .phone
            display: flex
            margin: 0 1.5rem
            img
              height: 2rem
            p
              font-size: 1.6rem
              margin: 0 .5rem
          .stats
            border-right: 1px solid $color-border
            padding: 0 1.5rem
            font-size: 1.4rem
            text-align: center
            h6
              margin: 0
              font-size: 2.2rem
            p
              margin: 0
      .secondPart
        display: flex
        align-items: center
        .paid,.waitingForPayment,.setup,.trial
          padding: .5rem 2rem
          font-size: 1.8rem
          background-color: red
          border: none
          margin: 0 1rem
          outline: 0
          color: white
          border-radius: .4rem
          cursor: default
          transition: all .2s
          outline: none
        .waitingForPayment
          font-size: 1.4rem
        .paid
          background-color: #7ED321
        .setup
          background-color: #ccc
        .trial
          background-color: deepskyblue
      .btnUpdate
        font-size: 1.8rem
        border: none
        outline: 0
        color: white
        transition: all .2s
        outline: none
        padding: .8rem 1.5rem
        border-radius: 4rem
        cursor: pointer
        background: linear-gradient(#febe19 0%, #feb817 46%, #fead12 100%)
        box-shadow: 0 3px 16px rgba(119, 116, 96, 0.12)
      .btnExport
        min-width: 129px
        font-size: 1.8rem
        border: none
        outline: 0
        color: white
        transition: all .2s
        outline: none
        padding: .8rem 1.5rem
        border-radius: 4rem
        cursor: pointer
        border: 1px #febe19 solid
        background-color: white
        color: #febe19
        box-shadow: 0 3px 16px rgba(119, 116, 96, 0.12)
      .drop
        height: 1.5rem
        width: 1.5rem
      .payment
        margin: 0 1rem
        h6
          margin: 0
          font-size: 1.5rem
        p
          font-weight: bold
          margin: 0
          font-size: 1.5rem

.switch
  border: none !important
  outline: none !important
  background-color: white !important
  text-align: center !important
  color: black !important
  box-shadow: none !important

.menuWrapper
  left: -3.8rem !important
  .item
    padding: 1rem
    text-align: left
    outline: 0 !important
  .item:hover
    background-color: rgba(254, 190, 25, .1)

.fullWidthDrop.dropdown.toggle
  display: flex !important
  flex-direction: row !important
  justify-content: space-between !important
  align-items: center !important
  width: 100%
  height: 40px
  line-height: 40px !important
  background: #f7f7f7 !important
  color: #2c2c2c !important
  font-size: 16px
  &:hover > *
    color: #1c1c1c !important
  span
    margin: 0px !important
    line-height: 40px !important
  svg
    align-self: flex-end !important
    transform: translateY(-2px) !important

.activity_logs
  padding: 24px
  width: 100%
  background-color: #fff
  border: solid 1px #e5e5ea
  .header
    color: #1c1c1c
    font-size: 20px
    font-weight: 700
    margin-bottom: 22.5px
  .section
    border-radius: 10px
    border: solid 1px #e5e5ea
    display: flex
    height: 500px
    & > *
      &:first-child
        border-radius: 10px 0px 0px 10px
      &:last-child
        border-radius: 0px 10px 10px 0px
    .due_tasks
      flex: 1
    .log
      padding: 24px
      background: #f9f9fb
      display: flex
      flex: 1
      flex-direction: column

.log_details
  padding: 10px 0px
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 14px
  color: #8E8E93
  .details
    strong
      color: #1c1c1c
      font-weight: 700
  .issued_at

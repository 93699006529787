@import "sass/colors"

*
  margin: 0
  padding: 0
  button
    cursor: pointer

*,
*::after,
*::before
  box-sizing: inherit
  cursor: inherit

#notificationsWrapper
  font-size: unset


html
  box-sizing: border-box
  font-size: 62.5%
  @media only screen and (max-width: $bg-largest-1)
    font-size: 70%
  @media only screen and (max-width: $bg-largest-2)
    font-size: 62.5%
  @media only screen and (max-width: $bg-largest-3)
    font-size: 53%
  @media only screen and (max-width: $bg-large)
    font-size: 43%
  @media only screen and (max-width: $bg-medium-1)
    font-size: 40%
  @media only screen and (max-width: $bg-medium-2)
    font-size: 30%
  @media only screen and (max-width: $bg-small)
    font-size: 25%
  @media only screen and (max-width: $bg-smallest-2)
    font-size: 15%

.wrapper
    display: flex
    flex: 1
    flex-direction: column
.headerWrapper
    display: flex
    align-items: center
    flex-direction: row
    justify-content: space-between
.merchantHeaderWrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 30px
    .title
        font-size: 32px
        font-weight: bold
        color: #000
    .buttonWrapper
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        padding: 17px 24px
        border-radius: 4px
        background-color: #ffa351
        font-size: 16px
        font-weight: bold
        color: #fff
        outline: none
        .plus
            width: 16px
            height: 16px
            margin-right: 8px

@import ../../sass/colors

.navBar
  width: 20rem
  height: 100vh
  background-color: #fff
  border-right: 1px solid $color-border
  border-bottom: 1px solid $color-border
  display: flex
  position: fixed
  flex-direction: column
  justify-content: flex-start
  align-items: center
  .items
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    flex: 1
    width: 100%
    padding: 5rem 0
    background-color: white
    .btn
      border: none
      margin: 0 1rem
      background-color: white
      color: black
      width: 55%
      font-size: 2.5rem
      outline: none
      display: flex
      align-items: center
      justify-content: space-between
      height: 5rem
    .btn:hover
      color: $color-shopx-main-theme
      border-radius: 40px

  .logo
    width: 100%
    height: 10rem
    background-color: white
    display: flex
    justify-content: center
    align-items: center
    img
      width: 5rem



.wrapper
  display: flex
  align-items: center
  justify-content: center
  margin: 3rem 0
  .card
    display: flex
    width: 45%
    flex-direction: column
    align-items: center
    background-color: white
    padding: 3rem
    margin: 1rem 0
    max-height: 60rem
    overflow: auto

.wrapper {
  background-color: #fefefe;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.wrapper .bodyWrapper {
  padding: 1.7rem 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.wrapper .bodyWrapper .bodyText {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.7px;
  color: #9b9b9b;
  flex: 1;
}
.wrapper .headWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
  border-bottom: 1px solid #ebebeb;
}
.wrapper .headWrapper .totalPromoCodesNum {
  font-size: 2.2rem;
  color: #000000;
}
.wrapper .headWrapper .icon {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}
.wrapper .headWrapper .createBtn {
  width: 19.7rem;
  height: 5.6rem;
  outline: none;
  border-radius: 0.5rem;
  background-color: #41126c;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
}
$color-light-yellow: #FFF8E5
$color-shopx-main-theme: #f09440
$color-shopx-main-background: #FBFBFB
$color-yellow-gradient-1: #f09440
$color-yellow-gradient-2: #f09440
$color-yellow-gradient-3: #f09440
$color-black-text: #2D2D2D
$color-light-black-subheader: #5D5D5D
$color-grey-paragraph: #B1B1B1
$color-green-status: #7ED321
$color-purple-status: #9409B1
$color-border: #F5F5F5
$bg-largest-1: 117.5em //1880
$bg-largest-2: 96em //1536
$bg-largest-3: 90em //1440
$bg-large: 68.75em //1100
$bg-medium-1: 56.25em //900
$bg-medium-2: 46.875em //750
$bg-small: 37.5em //600
$bg-smallest-1: 31.25em //500
$bg-smallest-2: 25em //400
$bg-smallest-3: 20em //321
$box-shadow: 0px 3px 13px rgba(54, 54, 0, 0.1)
$box-shadow-btn-hover: 0 0.5rem 1rem rgba(0, 0, 0, 0.1)
$box-shadow-btn-active: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.2)
$box-shadow-light: 0px 3px 10px rgba(0, 0, 0, 0.05)

@import '../../../../sass/colors'

.left
  right: unset !important
  left: 6px !important
.right
  left: unset !important
  right: 6px !important
.inputWrapper
  display: flex
  width: 100%
  margin: 0
  justify-content: flex-start !important
  flex-direction: column
  margin-bottom: 2.4rem

.label
  display: flex
  color: #2D2D2D
  flex: 1
  position: relative
  justify-content: space-between !important
  font-weight: bold
  margin-bottom: 1rem
  font-size: 1.6rem
  span
    margin: 0 !important
.formError
  font-weight: bold
  font-size: 1.6rem
  color: #FF0606
.input,.passInput
  width: 100%
  outline: none
  border-radius: 5px
  font-size: 1.8rem
  font-weight: 500
  padding: 0 2rem
  color: $color-black-text
  border: 1px solid $color-border
  &.active
    border-color: #fdbb11
  &.valid
    border-color: #38c172
  &.invalid
    border-color: #db3030
.input::placeholder
  font-size: 1.6rem
  font-weight: normal
.passInput::placeholder
  font-size: 5rem !important
  letter-spacing: -4px
.input:read-only
  background-color: #e2e3e5

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

input:disabled
  // border-radius: 5px !important
  // box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important
  // border: solid 1px #e3e3e3 !important
  color: #5e5e5e !important
  background-color: #ffffff !important
.inputAndLoadingWrapper
  display: flex
  width: 50.4rem
  height: 64px
  border-radius: 5px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #a6a6a6
  background-color: #ffffff
  align-items: center
  padding: 0 2rem
  .input
    border: none
    padding: 0
    flex: 1

.passwordMaskWrapper
  display: flex
  height: 5.5rem

.maskedPromoSharesWrapper
    display: flex
    flex-direction: column
    padding: 16px
    width: 100%
    background: #F2F2F7
    border-radius: 4px
    .maskedPromoLabel
        font-weight: bold
        font-size: 20px
        color: #1C1C1E
        margin-bottom: 16px
    .maskedPromoInputsWrapper
        display: flex
        flex-direction: row
        justify-content: space-between

    .maskedPromoInputWrapper
        display: flex
        flex-direction: column
        .maskedPromoInput
            padding: 16px
            height: 56px
            background: #FFFFFF
            border: 1px solid #A6A6A6
            box-shadow: inset 1px 1px 2px 1px rgba(0, 0, 0, 0.1)
            border-radius: 4px
            outline: none
            width: 100%
        .maskedPromoInput::placeholder
            font-size: 16px
            color: #8E8E93

        .maskedPromoInputLabel
            font-weight: 600
            font-size: 16px
            color: #2D2D2D

@mixin banner-card-layout
  min-width: 34rem
  height: 12rem
  object-fit: contain
  border-radius: 13px
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 6.7rem
  margin-right: 3rem


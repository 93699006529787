.wrapper
  width: 51.2rem
  height: 102.4rem
  box-shadow: -0.2rem 0.2rem 5.4rem 0 rgba(0, 0, 0, 0.1)
  background-color: #ffffff
  display: flex
  flex-direction: column
  .header
    display: flex
    justify-content: space-between
    padding: 12.5rem 3.3rem
    padding-bottom: 4rem
    .headerText
      font-size: 2.4rem
      font-weight: 900
      line-height: 0.83
      color: #000000
    .headerIcon
      width: 1.9rem
      height: 1.9rem
      cursor: pointer
  form
    padding: 0 3.2rem
    .select
      width: 100%
    .brand
      display: flex
      .addBtn
        width: 11.6rem
        height: 4.8rem
        border-radius: 4px
        background-color: #f09440
        font-size: 1.6rem
        font-weight: bold
        line-height: 1.13
        letter-spacing: 0.11px
        text-align: center
        color: #ffffff
        margin-left: 0.8rem !important
        margin-top: 5.5rem !important

.chipsWrapper
  display: flex
  flex-wrap: wrap

.promoType
  span
    font-size: 16px !important
    font-weight: 600 !important
    color: #2d2d2d
    margin-bottom: 1rem
  ul
    display: flex
    align-items: center
    justify-content: space-between
    list-style: none
    li
      border: solid 1px #f09440
      font-size: 1.4rem
      font-weight: 500
      cursor: pointer
    .active
      background-color: #f09440
      color: #ffffff
    .unActive
      background-color: none
      color: #f09440
    .l1
      padding: 1.5rem 3.4rem
      border-right-width: 0
    .l2
      padding: 1.5rem 2.8rem
      border-right-width: 0
    .l3
      padding: 1.5rem 2.72rem
.max
  width: 28rem
  margin: 2.3rem 0
.priceWrapper
  display: flex
  justify-content: space-between
  .secondInput
    width: 21.2rem

.submitBtn
  width: 20.5rem
  height: 5.6rem
  border-radius: 0.4rem
  background-color: #f09440
  font-size: 1.8rem
  font-weight: bold
  letter-spacing: 0.13px
  text-align: center
  color: #ffffff
  margin: auto 13.7rem
.loader
  display: flex
  justify-content: center

.formControl
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    paddding: 10px
    height: auto
    width: 100%

.label
    font-weight: 600
    font-size: 16px
    line-height: 22px
    color: #1C1C1C
input.input
    padding: 10px
    height: 62px
    border: 1px solid #eee
    border-radius: 10px
    width: 100%
    font-weight: 400
    font-size: 16px
    color: #1C1C1C
    &::placeholder
        font-weight: 400
        font-size: 16px
        line-height: 22px
        color: #65656C
    &:focus
        outline: 1px solid #FFA351

.invalid
    border: solid 1px #f00 !important
.valid
    border: solid 1px #0f0
.withBadge
    position: relative
    width: auto
    border-radius: 10px
    width: 100% !important
    .badge
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        top: 0
        right: 0
        bottom: 0
        width: 60px
        border-radius: 0 10px 10px 0
        background: #E5E5EA
        color: #1C1C1C
        &.invalid
            border-top: solid 1px #f00 !important
            border-bottom: solid 1px #f00 !important
            border-right: solid 1px #f00 !important
            border-left: solid 1px transparent !important
.loadingWrapper
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  height: 89vh
  .override
    display: block
    margin: 0 auto
    border-color: red

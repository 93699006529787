@import ../../sass/colors

.wrapper,.wrapperError
  border: none
  padding: 24px
  background-color: white
  display: flex
  flex-direction: column
  justify-content: space-between
  border: solid 1px #e5e5ea
  flex: 3
  span
    margin: 0
  .header
    display: flex
    justify-content: space-between
    align-items: center
    padding-bottom: 24px
    p
      margin: 0
      font-size: 20px
      font-weight: bold

      color: #1c1c1e
    .select
      margin-left: 12px
      border-radius: 50px !important
      height: 40px
      button
        width: 100%
        height: 40px
        display: flex
        flex-direction: row
        align-items: center
        padding: 8px 12px
        border-radius: 50px
        img
          width: 12px !important
          height: 12px !important
  .body
    display: flex
    justify-content: space-between
    align-items: flex-end
    img
      height: 5rem
    .stats
      span,h1
        margin: 0
        font-weight: bold
.wrapper.flexbox-row-last-child
  background-color: red
.wrapperError
  background-color: red
  .header
    p
      color: white
  .body
    .stats
      h1
        color: white
      span
        color: white

@media only screen and (max-width: $bg-largest-3)
  .wrapper,.wrapperError

@import "./BannerCardItem/banner-card"
.wrapper
  background-color: #fafafa
  padding: 4rem 3rem
  .header
    display: flex
    justify-content: space-between
    align-content: center
    margin-bottom: 2.4rem
    .title
      font-size: 2.2rem
    .addCardCTA
      display: flex
      padding: 0 1rem
      min-width: 19.7rem
      height: 5.6rem
      border-radius: 5px
      background-color: #41126c
      align-items: center
      justify-content: center
      font-size: 1.4rem
      color: #ffffff
      .addIcon
        margin: 0 1rem
        width: 2rem
        height: 2rem
  .cardsWrapper
    padding: 4rem
    background: #f2f2f2
    border-radius: 5px
    display: flex
    flex-wrap: wrap
    .addNewCardPlaceholder
      @include banner-card-layout
      border: dashed 2px #a6a6a6
      .addImg
        border-radius: 50%
        background-color: rgba(#f09440, 0.2)
        width: 7.2rem
        height: 7.2rem
        display: flex
        justify-content: center
        align-items: center
        i
          font-size: 3rem
          color: #ffffff




.dropDownPart
  display: flex
  flex-direction: column
  margin: 0
  span
    font-size: 1.6rem
    color: #222222
    position: relative
    margin: 0
  .switch
    font-size: 1.6rem !important
    margin: 0 !important
    width: 100%
    border-radius: 5px !important
    border: solid 1px #ebebeb !important
    outline: none !important
    background-color: white !important
    text-align: left !important
    padding: 0 !important
    font-weight: 500 !important
    color: #222222 !important
    box-shadow: none !important
    div
      display: flex !important
      align-items: center !important
      justify-content: space-between !important

  .switch:disabled
    background-color: #F3F3F3 !important
    cursor: default

.menuWrapper
  max-height: 30rem
  overflow: auto
  min-width: 100% !important
  padding: 0 !important
  box-shadow:  0px 8px 16px 0px rgba(0,0,0,0.2) !important
  background-color: #ffffff !important
  border: 0 !important
  button
    padding: 0 !important
    .item
      padding: 1.6rem 2rem
      font-size: 1.8rem
      outline: 0 !important
    .specialItem
      position: sticky
      bottom: 0
      background-color: #41126c
      padding: 1.6rem 2rem
      box-shadow: 0 -2px 10px 0 rgba(35, 0, 66, 0.15)
      background-color: #41126c
      text-align: left
      color: #ffffff
      height: 6.5rem
      font-size: 1.6rem
      display: flex
      justify-content: space-between
      align-items: center
      .imgWrapper
        width: 4.2rem
        height: 4.2rem
        border-radius: 2.1rem
        background-color: #5b1998
        .img
          margin: auto
          width: 2rem
          height: 2rem

.dropdown-item , .item
  &:last-child
    position: sticky
    bottom: 0
    padding: 0

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  // padding: 12.5rem 0
  .headerWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 12.5rem 3.3rem 0 3.3rem
    width: 100%
    .header
      font-size: 2.8rem
      font-weight: 900
      color: #1a1a1a
    .icon
      width: 19px
      height: 19px
  .formWrapper
    width: 51.2rem
      // height: 50.8rem
    // border-radius: 0.5rem
    // box-shadow: 0 1.2rem 2.4rem 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    form
      width: 100%
      padding: 3.2rem
      .uploadBtn
        width: 10rem
        height: 2rem
        text-decoration: underline
        background-color: #ffffff !important
        font-size: 1.4rem !important
        font-weight: bold !important
        border: 1px solid #ffffff
        line-height: 1.29
        display: flex
        align-items: center
        justify-content: flex-start
        letter-spacing: .1px
        text-align: center
        color: #f09440
        margin: 2rem 0
      .fileWrapper
        display: flex
        flex-direction: row
        align-items: center
        .fileLab
          text-decoration: underline
          font-size: 1.6rem
          margin: 0 1.6rem
          font-weight: 500
          color: #f09440
          padding-top: 2.5rem
      .submitBtn
        width: 100%
        height: 5.6rem
        border-radius: 0.4rem
        background-color: #f09440
        font-size: 1.8rem
        font-weight: bold
        letter-spacing: 0.13px
        text-align: center
        color: #ffffff
        margin-top: 5.2rem
        margin-bottom: 3.2rem

.fileName
  font-size: 1.2rem
  font-weight: 500
  color: #1a1a1a

.dropDownPart,.fullWidthDrop,.dropDownPartForUsers
  width: 49%
  display: flex
  flex-direction: column
  margin: 1rem 0
  margin-bottom: 2.4rem
  span
    font-size: 16px !important
    color: #2D2D2D !important
    font-weight: 600
    position: relative
    margin: 0
    margin-bottom: 1rem !important
  .switchForUsers
    font-size: 1.8rem !important
    border: 1px solid #a6a6a6
    margin: 0 !important
    width: 100%
    display: flex
    justify-content: space-between
    border-radius: 0.5rem !important
    padding: 1.5rem 1rem !important
    outline: none !important
    background-color: white !important
    text-align: left !important
    color: black !important
    box-shadow: none !important
  .switch:disabled
    background-color: #F3F3F3 !important
    cursor: default
.fullWidthDrop
  width: 100%

.menuWrapper
  width: 100%
  .item
    padding: 1rem
    text-align: center
    font-size: 1.8rem
    outline: 0 !important
  .item:hover
    background-color: rgba(254,190,25,.1)

.passwrodLabel
  display: flex
  position: relative
  justify-content: space-between
  margin: 0
  font-size: 1.6rem
  font-weight: bold
  color: #2d2d2d
  span
    margin: 0
  .formError
    color: #FF0606 !important
.label
  display: flex
  position: relative
  justify-content: space-between
  margin: 0
  color: #606977
  font-size: 1.5rem
  span
    margin: 0
  .formError
    color: #FF0606

.wrapper, .mode
    display: flex
    flex-direction: column
    border-radius: 8px
    border: 1px solid #E5E5EA
    background-color: #FFF
    box-shadow: 0px 4px 8px 0px rgba(142, 142, 147, 0.12)
    .actionsWrapper, .saveChangesWrapper
        display: flex
        flex-direction: row
        align-items: center
        border-top: 2px solid #F2F2F7
        padding: 24px
        margin-top: 32px

        .separate
            background: #D1D1D6
            width: 1px
            height: 27px
            margin: 0 8px
        .exportLoadingIndicator
            display: flex
            justify-content: center
            align-items: center
            width: 135px
            height: 40px    
        .button
            background-color: transparent
            padding: 10px 12px
            color: #65656C
            font-size: 14px
            font-weight: 700
            display: flex
            flex-direction: row
            border: none
            outline: none
            .icon
                width: 16px
                height: 16px
                margin-right: 8px
    .saveChangesWrapper
        justify-content: flex-end
        gap: 16px
        .saveChangesBtn, .cancelBtn, .disabledSaveChangesBtn
            border: none
            outline: none
            font-size: 16px
            font-weight: 700
            color: #FFF
            border-radius: 8px
            padding: 8px 12px
            background-color: #FF9000
        .saveChangesBtn
            min-width: 129px    
        .cancelBtn
            border-radius: 8px
            background-color: #F2F2F7
            color: #1C1C1E
        .disabledSaveChangesBtn
            border-radius: 8px
            background-color: #F2F2F7
            color: #1C1C1E
            cursor: not-allowed
    .account
        flex: 1
        display: flex
        justify-content: flex-end
        font-size: 14px 
        color: #1C1C1E
        font-weight: 400
        .reference
            color: #8E8E93
            margin-left: 4px
    .posStatusesWrapper
        display: flex
        flex-direction: row
        align-items: center
        gap: 8px
        padding: 24px
        .posActive, .posInactive, .posUnavailable    
            padding: 8px 12px
            border-radius: 48px
            background: #E6FFEE
            color: #0FCB5D
            font-size: 14px
            font-weight: 700
        .posInactive    
            background: #FFEEF6
            color: #E40031
        .posUnavailable
            background: #FEBE1920
            color: #FEBE19
    .fieldsWrapper
        padding: 24px 24px 0 24px
        display: flex
        flex-direction: row
        gap: 20px
.field, .apiKeyWrapper
    display: flex
    flex-direction: column
    width: 100%
    .label
        color: "#1C1C1C"
        font-size: 16px
        font-weight: 600
        margin-bottom: 4px
        margin-right: 4px
        .optional
            color: #65656C
            font-size: 16px
            font-weight: 400
    .input
        width: 100%
        height: 48px
        padding: 12px 16px
        border: 1px solid #D1D1D6
        border-radius: 8px
        gap: 12px
        outline: none
.apiKeyWrapper
    margin-top: 32px
    padding: 0 24px
    .apiKey
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 8px 16px
        border-radius: 4px
        border: 1px solid #E5E5EA
        gap: 24px
        height: 48px
        .copyIcon
            cursor: pointer
        .apiKeyText
            color: #1C1C1E
            font-size: 16px
            font-weight: 400
            white-space: nowrap
.mode
    background-color: #F9F9FB

.wrapper
    display: flex
    flex-direction: column
    padding: 24px 24px 50px 24px
    .contactTabsViews
        display: flex
        flex-direction: row
        align-items: center
        margin-top: 12px
        .contactTabView, .selectedContactTabView
            cursor: pointer
            display: flex
            align-items: center
            padding: 2px 8px
            height: 28px
            background: #FFFFFF
            border: 1px solid #D1D1D6
            font-weight: 400
            font-size: 16px
            color: #8E8E93
        .selectedContactTabView
            background-color: #8E8E93
            border-color: #8E8E93
            font-weight: 600
            font-size: 16px
            color: #FFFFFF

    .templateMessageInputWrapper
        display: flex
        flex-direction: column
        margin-top: 24px
        padding: 12px 16px 0 12px
        max-width: 513px
        width: 100%
        height: 98px
        background: #F9F9FB
        border: 1px solid #F2F2F7
        border-radius: 4px
        .input
            resize: none
            background-color: transparent
            border: none
            outline: none
            max-width: 100%
            width: 513px
            height: 100%
            padding: 0
            font-weight: 400
            font-size: 16px
            color: #65656C
        .textCount
            font-weight: 400
            font-size: 16px
            text-align: right
            color: #8E8E93

    .templateMessageTitleWrapper
        display: flex
        flex-direction: row
        align-items: center
        .copyTemplateMessageWrapper
            display: flex
            flex-direction: row
            align-items: center
            margin-left: 8px
            .copyText
                font-weight: 600
                font-size: 14px
                color: #65656C
            .copyTemplateMessageIcon
                cursor: pointer
                margin-right: 4px
                width: 16px
                height: 16px
        .templateMessageTitle
            font-weight: 700
            font-size: 16px
            color: #65656C
    .title
        font-weight: 700
        font-size: 16px
        color: #65656C
        padding-bottom: 16px
    .contactTabsWrapper
        display: flex
        flex-direction: column
        padding-bottom: 32px
        .contactMethodsTabs
            display: flex
            flex-direction: row
            align-items: center
            cursor: pointer
            .contactTabWrapper, .activeContactWrapper
                margin-right: 8px
                display: flex
                align-items: center
                flex-direction: row
                justify-content: space-between
                width: 100%
                height: 44px
                background: #FFFFFF
                border: 1px solid #D1D1D6
                border-radius: 4px
                padding: 12px
                .checkboxWrapper,.checkedBoxWrapper
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 16px
                    height: 16px
                    background: #F2F2F7
                    border: 1px solid #8E8E93
                    box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
                    border-radius: 4px
                .checkedBoxWrapper
                    background: #FFA351
                    border: 1px solid #FFA351
                    box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
                .titleWrapper
                    display: flex
                    flex-direction: row
                    align-items: center
                    .methodTitle
                        font-weight: 400
                        font-size: 14px
                        color: #1C1C1C
                    .methodIcon
                        width: 20px
                        height: 20px
                        margin-right: 8px
            &:last-child
                margin-right: 0
            .activeContactWrapper
                border: 1px solid #FFA351
.switch
  border: solid 1.5px rgba(0, 0, 0, 0.1)
.settingsWrapper
  margin-top: 7.4rem
  .settingWrapper
    display: flex
    cursor: pointer
    align-items: center
    justify-content: space-between
    margin-bottom: 1.1rem
    border-bottom: 1px solid #ebebeb
    padding-bottom: 0.5rem
    .settingText
      .settingTitle
        margin-bottom: 0.4rem
        font-size: 1.8rem
        font-weight: 500
        color: #2d2d2d
      .settingSubTitle
        font-size: 1.2rem
        color: #9b9b9b
    .settingSwitch
      margin-left: 16.4rem
      direction: ltr !important
      border: solid 1.5px rgba(0, 0, 0, 0.1)


.settingsWrapper.rtl
  .settingWrapper
    .settingSwitch
      margin-left: 0
      margin-right: 16.4rem
      border: solid 1.5px rgba(0, 0, 0, 0.1)
      

.settingSwitch.rtl
  transform: scale(-1) !important

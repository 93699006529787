.main
    width: 100%
    align-self: stretch
    display: flex
    flex-direction: column
    padding: 24px
    border: solid 1px #e5e5ea
    background-color: #fff
    .wrapper
        display: flex
        flex-direction: row
        align-items: center
        .activeCard, .disabled
            margin-right: 24px
            height: 36px
            display: flex
            flex-direction: row
            justify-content: center
            padding: 8px 12px
            border-radius: 128px
            border: solid 1px #38c172
            background-color: #fff
            .serviceIcon
                width: 20px
                height: 20px
                margin-right: 8px
            .service
                font-size: 14px
                font-weight: bold
                color: #38c172
        .disabled
            border: solid 1px #8e8e93
            background-color: #fff
            .service
                color: #8e8e93
    .header
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin-bottom: 22.5px
        .title
            font-size: 20px
            color: #1c1c1e
            font-weight: bold
        .editButton
            width: 40px
            height: 40px
            display: flex
            justify-content: center
            align-items: center
            padding: 8px
            border-radius: 120px
            background-color: #eae8f3
            cursor: pointer
            .editIcon
                width: 24px
                height: 24px

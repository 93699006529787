@import ../../sass/colors

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  margin: 8rem 0 5rem
  .title
    font-size: 2.2rem
  .cardsWrapper
    display: flex
    margin: 2rem 0
    justify-content: center
    align-items: center
    width: 100%
    .card
      cursor: pointer
      border: none
      box-shadow: $box-shadow
      padding: 1.2rem
      background-color: white
      margin: 2.5rem
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      border-radius: .5rem
      width: 20%
      transition: box-shadow 150ms linear
      .img
        margin: 0
        width: 5rem
        height: 5rem
      .plan
        margin: 1.5rem 0 0
        font-size: 1.8rem
    .card:hover
      box-shadow: $box-shadow-btn-hover

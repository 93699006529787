.wrapper
  display: flex
  align-items: center
  .downloadTemplate
    color: #f09440
    font-size: 1.6rem
    font-weight: 500
    margin: 0 1.6rem
    cursor: pointer
    text-decoration: underline
  .uploadButton, .dimmedButton
    cursor: pointer
    width: 16.6rem
    height: 4rem
    border-radius: 4px
    background-color: #f09440
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.4rem
    font-weight: bold
    color: #ffffff
  .dimmedButton
    cursor: not-allowed
    background-color: #d2d2d2  
.fileName
  font-size: 1.2rem
  font-weight: 500
  color: #1a1a1a

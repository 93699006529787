.wrapper
  width: 86.4rem
  // height: 68.4rem
  border-radius: 10px
  background-color: #ffffff
  display: flex
  align-self: center
  flex-direction: column
  align-items: center
  padding-top: 4rem
  .tagsCard
    width: 78.4rem
    height: 52.8rem
    border-radius: 5px
    box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
    background-color: #fafafa
    padding: 4rem
    flex-wrap: wrap
  .separator
    margin-top: 2.7rem
  .searchWrapper
    width: 78.4rem
    height: 5.2rem
    border-radius: 5px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
    border: solid 1px #ebebeb
    background-color: #ffffff
    .input
      width: 70.4rem
      height: 4rem
      border: none
      margin: 0.5rem
      outline: 0
      font-size: 1.6rem
      line-height: 0.88
      color: #595959
      padding: 1.9rem 2rem

    .icon
      width: 2rem
      height: 2rem
      position: relative
      right: 1.5rem

.wrapper
  width: 36rem
  height: 5rem
  border-radius: 5px
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
  border: solid 1px #ebebeb
  background-color: #ffffff
  margin-right: 1.6rem
  display: flex
  align-items: center
  padding: 0 1rem
  .input
    width: 90%
    height: 3rem
    outline: none
    border-radius: 5px 
    font-size: 1.8rem
    color: #000000
    border: 1px solid #ffffff
    text-align: left
    ::placeholder
      font-size: 1.6rem
      line-height: 0.88
      color: #9b9b9b
  .icon
    width: 2rem
    margin-left: 2rem
    height: 2rem  
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0  

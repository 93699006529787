@import ../../sass/colors

.wrapper
  display: flex
  justify-content: flex-end
  align-items: center
  width: 100%
  .card
    border: none !important
  .cardBody
    padding: 0 !important
    display: flex
    align-items: center
  .confirmBtn
    border: none !important
    box-shadow: none !important
    outline: none !important
    color: white !important
    font-size: 1.8rem
    width: 10rem
    height: 4rem
    background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
    border-radius: 21px
  .confirmBtn
    padding: 0
    width: 4rem
    height: 4rem
    border-radius: 50%
    font-size: 2.5rem
    font-weight: bold
  .text
    font-size: 1.4rem
    margin: 0 1rem
    color: black

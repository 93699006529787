.CreateMenu
  width: 28.8rem
  height: 5.6rem
  border-radius: 0.5rem
  background-color: #f09440
  font-size: 1.6rem
  font-weight: bold
  letter-spacing: 0.11px
  text-align: center
  color: #ffffff
  outline: none
  
.modal
  display: flex
  align-items: center
  justify-content: center
.main   
  width: 56.8rem
  height: 61.3rem
  overflow: scroll
  border-radius: 1rem
  box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
  background-color: #ffffff
  
  .paper
    padding: 3.2rem
    h2 
      font-size: 2.2rem
      font-weight: 500
      color: #2d2d2d
    .menuVat
      width: 23.5rem
      margin-top: 3.2rem
    .menuName
      margin-top: 3.2rem
  .footer
    width: 100%
    height: 8rem
    border-radius: 1rem
    background-color: #f2f2f2
    display: flex
    justify-content: flex-end
    flex-direction: row
    padding: 1.6rem 2.4rem
    .Create
      width: 14.4rem
      height: 4.8rem
      border-radius: 0.5rem
      background-color: #f09440
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
    .Cancel
      margin: 0 0.8rem
      width: 14.4rem
      height: 4.8rem
      background-color: #ffffff
      border-radius: 0.5rem
      border: solid 1px #ee8728
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ee8728



.createBtn
  width: 19.7rem
  height: 5.6rem
  outline: none
  border-radius: .5rem
  background-color: #41126c
  font-size: 1.4rem
  font-weight: bold
  line-height: 1.29
  letter-spacing: 0.1px
  text-align: center
  color: #ffffff
.icon
  width: 2rem
  height: 2rem
  object-fit: contain


.imageUploadWrapper
  width: 75%
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 1rem
  .dropZoneWrapper,
  .imagePreviewWrapper
    width: 12.5rem
    height: 12.5rem
.label
    font-size: 1.6rem
    font-weight: bold
    color: #1a1a1a

@import ../../sass/colors

.inputWrapper,.fullWidthInput,.promoIdInput .commissionWrapper
  display: flex
  width: 49%
  margin: 1rem 0
  flex-direction: column
  margin-bottom: 2.4rem
  align-items: flex-start
.promoIdInput
  width: 100%
  margin: 0
  margin-bottom: 2.4rem
  .label
    display: flex
    align-items: center
    flex-direction: row
    .optional
      font-size: 1.4rem
      margin: 0 1rem
      font-weight: 500
      color: #a6a6a6
    span
      font-size: 16px !important
      font-weight: bold !important
      color: #1a1a1a
  .input
    height: 5.5rem
    border-radius: .5rem
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
    border: solid 1px #a6a6a6
    background-color: #ffffff
.commissionWrapper
  width: 23.2rem
  margin-bottom: 0
  .label
    font-size: 1.6rem
    font-weight: bold
    color: #2d2d2d

  .postfixWrapper
    width: 8.7rem
    object-fit: contain
    border-radius: 5px
    background-color: #cccccc
.passwrodLabel
  display: flex
  position: relative
  justify-content: space-between
  margin: 0
  font-size: 16px
  font-weight: 600
  color: #2d2d2d
  span
    margin: 0

.label
  display: flex
  position: relative
  justify-content: space-between
  margin: 0
  color: #606977
  font-size: 1.5rem
  margin-bottom: 1rem
  span
    margin: 0
  .formError
    color: #FF0606
.passInputWrapperForUser
  border-radius: 5px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important
  border: solid 1px #a6a6a6 !important
  background-color: #ffffff !important
.input,.passInput,.passInputWrapperForUser
  height: 5.5rem
  outline: none
  border-radius: 5px
  font-size: 1.8rem
  padding: 0 1rem
  color: $color-black-text
  border: 1px solid $color-border
  text-align: left
  width: 100%
.fullWidthInput
  width: 100%
.input:read-only
  background-color: #e2e3e5
.dropDownPartForUsers
  width: 450px
  height: 55px
  border-radius: 5px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #a6a6a6 !important
  background-color: #ffffff
.dropDownPart,.fullWidthDrop,.dropDownPartForUsers
  width: 49%
  display: flex
  flex-direction: column
  margin: 1rem 0
  span
    font-size: 1.5rem
    color: #606977
    position: relative
    margin: 0
  .switchForUsers
    border: solid 1px #a6a6a6 !important
    background-color: #ffffff
  .switch, .switchForUsers
    font-size: 1.8rem !important
    border: 1px solid $color-border
    height: 6rem
    margin: 0 !important
    width: 100%
    border-radius: 1rem !important
    padding: 0 1rem !important
    outline: none !important
    background-color: white !important
    text-align: left !important
    color: black !important
    box-shadow: none !important
  .switch:disabled
    background-color: #F3F3F3 !important
    cursor: default
  .showArrowWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between  
.fullWidthDrop
  width: 100%

.menuWrapper
  width: 100%
  .item
    padding: 1rem
    text-align: center
    font-size: 1.8rem
    outline: 0 !important
  .item:hover
    background-color: rgba(254,190,25,.1)

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

.datePickerWrapper,.fullWidthDate
  width: 49%
  display: flex
  flex-direction: column
  margin: 1rem 0
  span
    font-size: 16px
    font-weight: 600
    position: relative
    margin: 0
    color: #000000
  .datePicker
    display: flex
    align-items: center
    padding: 0
    position: relative
    width: 100% !important
    border-radius: 5px
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
    border: solid 1px #a6a6a6
    div:first-child
      div
        bottom: 6rem
      .dateInput
        width: 100%
        outline: none
        height: 6rem
        border-radius: 1rem
        padding: 0 1rem
        font-size: 1.8rem !important
        border: 1px solid $color-border !important
        color: black
      input:disabled
        background-color: #F3F3F3
      .time:disabled
        background-color: #F3F3F3
    .icon
      position: absolute
      right: 0
      font-size: 2rem
      padding: 0 1rem

.fullWidthDate
  width: 100%
  .datePicker
    div:first-child
      div
        bottom: auto

.textAreaWrapper
  display: flex
  width: 100%
  margin: 1rem 0
  flex-direction: column
  margin-top: 24px
.textAreaLabel
  position: relative
  display: flex
  color: #2D2D2D
  flex: 1
  position: relative
  justify-content: space-between !important
  font-weight: 600
  font-size: 16px
  margin-bottom: 1rem
  span
    margin: 0
  .formError
    color: #FF0606
.textArea
  width: 100%
  height: 20rem
  outline: none
  resize: none
  font-size: 1.8rem
  padding: 1rem
  color: $color-black-text
  // border: 1px solid $color-border
  border-radius: 0.5rem
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  border: solid 1px #a6a6a6

.inputAndLoadingWrapper,.inputAndCommissionLoadingWrapper,.rtl
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
  display: flex
  width: 100%
  align-items: center
  border: 1px solid #a6a6a6
  background-color: #fff
  padding: 0 0 0 2rem
  border-radius: 5px
  height: 5.7rem
  .input
    border: none
    padding: 0
  .genericInputBadge
    width: 2.4rem
    height: 2.4rem
  .postfixWrapper
    width: 8.1rem
    height: 5.5rem
    object-fit: contain
    border-radius: 0 5px 5px 0
    background-color: #cccccc
    display: flex
    justify-content: center
    align-items: center
    span
      margin: 0
    .postFix
      font-size: 1.8rem
      font-weight: 900
      color: #1a1a1a
      padding: 2.4rem 1.7rem

.active
  border: 1px solid #fdbb11 !important
.valid
  width: 100%
  border: 1px solid #38c172 !important
.invalid
  width: 100%
  border: 1px solid #db3030 !important

.fileWrapper
  display: flex
  flex-direction: column
  span
    font-size: 1.6rem
    font-weight: bold
    color: #2d2d2d
    margin-bottom: 1rem
  .labelFile
    width: 16.6rem
    height: 4rem
    border-radius: 0.4rem
    background-color: #f09440
    cursor: pointer
    span
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: normal
      text-align: center
      color: #ffffff
      position: relative
      top: 1.1rem
      left: 4.2rem

      .file
        position: absolute
        opacity: 0
        left: 3.3rem
        width: 16.6rem
        height: 4rem

.formError
  color: #FF0606 !important

.uploadCoverPart
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  width: 50rem
  .img
    width: 50rem
    height: 14rem
  .uploadWrapper
    display: flex
    align-self: flex-end
    margin: 0
    flex-direction: column
    justify-content: center
    align-items: flex-end
    .max
      font-size: 1.2rem
      color: #A4A4A4
      margin: 1rem 0
    .uploadBtn
      background: $color-shopx-main-theme
      outline: none !important
      color: white
      border: 0
      font-size: 1.4rem
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      font-weight: 700
      width: 13rem
      height: 4rem
      border-radius: 3rem

.customSelectWrapper
  display: flex
  align-items: center
  height: 48px
  width: 100%
  list-style: none
  .inactive
    background-color: #ffffff
    color: #f09440
  .active
    background-color: #f09440 !important
    color: #ffffff
  .allUsers, .newUsers, .orderingUsers
    width: 100%
    height: 100%
    text-align: center
    padding: 1.5rem 0
    display: flex
    justify-content: center
    align-items: center
    font-weight: bold
    font-size: 16px
    cursor: pointer
    &:active , &:focus
      outline: none
  .allUsers
    border-radius: 5px 0 0 5px
  .orderingUsers
    border-radius: 0 5px 5px 0

  .newUsers
    border: solid .5px #f09440
    border-right-width: 0
    border-left-width: 0

.customWrapper
  display: flex
  justify-content: space-between

.customLabel
  font-size: 1.6rem
  font-weight: bold
  color: #2d2d2d
.inputAndCommissionLoadingWrapper
  .input
    height: 5.2rem
  .postfixWrapper
    width: 8.7rem
    height: 5.5rem
    object-fit: contain
    border-radius: 0 5px 5px 0
    background-color: #cccccc
    display: flex
    justify-content: center
    align-items: center
    span
      margin: 0
      font-size: 1.6rem
      font-weight: 900
      color: #1a1a1a
    .postFix
      font-size: 1.8rem
      font-weight: 900
      color: #1a1a1a
      padding: 2.4rem 1.7rem
    .selction
      background: transparent
      color: #000
      width: 100%
      border: none
      font-size: 20px
      margin: 5px
      padding: 0
      &:active
        outline: none
      option
        height: 15px
        background: white
        text-align: center

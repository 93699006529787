.wrapper
  // margin: 0 16px
  .label
    color: #2d2d2d
    font-size: 1.6rem
    font-weight: bold
    margin-bottom: 11rem
    color: #2d2d2d
.dropdown
  display: flex !important
  justify-content: flex-start !important
  .dropdownToggle
    display: flex
    align-items: center
    width: 100%
    font-size: 16px !important
    background: #fff !important
    color: #1a1a1a !important
    text-align: left !important
    height: 40px !important
    padding: 8px
    border-radius: 5px !important
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important
    border: solid 1px #ebebeb !important
    .selectedValue
      font-size: 16px
      color: #595959

    .dropdownLabel
      font-size: 16px
      font-weight: 500
      color: #1a1a1a
      margin: 0 4px
    .dropdownIcon
      width: 20px
      height: 20px
      margin: 0 0 0 40px

  .arDropdownToggle
    text-align: right !important
  .menu
    width: 360px
    font-size: 16px
    padding-top: 0px
    position: absolute
    left: auto !important
    border: 0 !important
    margin-top: 4px !important
    border-radius: 5px !important
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2) !important 
  .dropdownItem
    display: flex
    justify-content: flex-start
    align-items: center
    cursor: pointer
    padding: 8px
    border-top: 1px solid rgba(0, 0, 0, 0.15)
    &:hover
      background: transparent
    &:focus,
    &:visited
    &:active
      color: #1a1a1a
    .checkedBox, .checkboxSelected
      width: 24px
      height: 24px
      margin: 0 8px 0 0
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px
      border: solid 1px #a6a6a6
      background-color: #ffffff
    .checkboxSelected
      background-color: #f48221  
      border: 0
      .checkboxIcon
        width: 100%
        height: 100%
  .searchBox
    background: #f2f2f2
    padding: 10px
    border-radius: 5px 5px 0 0
    .search
      display: flex
      justify-content: space-between
      border: 1px solid #E3E3E3
      border-radius: 8px
      padding: 10px
      align-items: center
      background: white
      .searchIcon
        height: 20px
      .input,.input::placeholder
        outline: none
        border: none
        color: #a6a6a6
        width: 100%
        padding: 0
        height: 100%
        font-size: 16px
      .input:disabled
        background-color: white
  .menuItems
    max-height: 200px
    overflow-y: scroll
    border-radius: 0 0 5px 5px
    .notFound
      padding: 12px
      display: flex
      justify-content: center
      width: 100%
  .menuItems::-webkit-scrollbar
    width: 0
.checked
  fill: #ebc2c2

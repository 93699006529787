.main
    padding: 0 24px
    padding-top: 24px
    .wrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 18.5px 16px
        border-radius: 4px
        .labelWrapper
            display: flex
            flex-direction: row
            align-items: center
            .infoIcon
                width: 24px 
                height: 24px
                margin-left: 10px
                cursor: pointer
        .maskedPromoTitle
            font-weight: bold
            font-size: 20px
            color: #1C1C1E
.tooltipWrapper
    border-radius: 8px
    max-width: 275px
    font-size: 14px
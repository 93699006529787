.pos-vendor .dropdown .btn-secondary {
  background-color: #fff !important;
  height: 48px !important;
  padding: 12px 16px !important;
  border: 1px solid #D1D1D6 !important;
  border-radius: 8px !important;
}
.pos-vendor {
  margin: 0 !important;
  margin-bottom: 0 !important;
}
.pos-vendor span {
  margin-bottom: 4px !important;
}

@import ../../sass/colors

.wrapper
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  margin: 1rem 0
  .btn
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
    border: none
    width: 16.84rem
    margin: 2rem 0 0 auto
    height: 5rem
    font-size: 1.8rem
    outline: 0
    cursor: pointer
    border-radius: 4rem
    color: white
    transition: all .2s
    outline: none !important
  .btn:hover
    transform: translateY(-3px)
    box-shadow: $box-shadow-btn-hover
  .btn:active
    box-shadow: $box-shadow-btn-active
    transform: translateY(-1px)
  .inputWrapper
    display: flex
    width: 49%
    margin: 1rem 0
    flex-direction: column
    .label
      position: relative
      margin: 0
      color: #606977
      font-size: 1.5rem
    .input,.passInput
      height: 6rem
      outline: none
      border-radius: 1rem
      font-size: 1.8rem
      padding: 0 1rem
      color: $color-black-text
      border: 1px solid $color-border
      text-align: left


.form
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  margin: 1rem 0

.wrapper
  box-shadow: 0 7px 14px 0 rgba(7, 4, 4, 0.1)
  background-color: #fefefe
  margin-bottom: 2rem
  margin-top: 2rem

  .bodyWrapper
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #ebebeb
    height: 55px
    padding: 1.7rem 1.6rem

    .bodyText, .promoCodeNameText, .promoCodeTypeText
      font-size: 14px
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      display: flex
      flex: 1

    .bodyText
      justify-content: flex-start
      flex: 1.15

    .rankWrapper
      flex: 0.6
      .rankContent,.dammedRankContent
        display: flex
        flex-direction: row
        align-items: center
        width: 77px !important
        padding: 12px 12px 15px
        height: 100%
        .rankText, .dammedRankText
          font-size: 14px
          font-weight: 900
          letter-spacing: 0.7px
          margin-right: 4px
          color: #1a1a1a

        .dammedRankText
          color: #9b9b9b

        &:hover
          background-color: #f2f2f2
          cursor: pointer

      .dammedRankContent
        cursor: not-allowed
        &:hover
          background-color: #f2f2f2
          cursor: not-allowed

      .arrowsWrapper
        display: flex
        flex-direction: column
        .arrowUp, .disabled
          cursor: pointer
          .disabled
            cursor: not-allowed

  .headWrapper
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1.5rem 1.6rem
    border-bottom: 1px solid #ebebeb

    .totalPromoCodesNum
      font-size: 2.2rem
      color: #000000

    .rightContainer
      display: flex
      align-items: center
      .closeIcon
        width: 1.5rem
        height: 1.5rem
        cursor: pointer

      .searchInput
        width: 370px
        margin-right: 16px
        border-radius: 9px
        align-items: center
        .ant-input
          padding: 10px
          font-size: 14px
          color: #333

      .icon
        width: 2rem
        height: 2rem
        object-fit: contain

      .createBtn
        width: 19.7rem
        height: 5.6rem
        outline: none
        border-radius: .5rem
        background-color: #41126c
        font-size: 1.4rem
        font-weight: bold
        line-height: 1.29
        letter-spacing: 0.1px
        text-align: center
        color: #ffffff

.table
    width: 100%
    background-color: #F9F9FB
    height: 100%
    .thead
        height: 32px
        tr

            box-shadow: inset 0px 1px 0px #F2F2F7, inset 0px -1px 0px #F2F2F7
            th
                font-weight: 700
                font-size: 14px
                color: #8E8E93
                padding: 0 12px
                &:nth-child(4)
                    .percentageWrapper
                        display: flex
                        flex-direction: row
                        align-items: center
                        .arrowsWrapper
                            display: flex
                            flex-direction: column
                            padding: 4px 0 4px 4px
                            .percentageSortIcon
                                cursor: pointer
                                width: 12px
                                height: 12px

                &:first-child
                    width: 40px

    .tbody
        tr
            box-shadow: inset 0px -1px 0px #F2F2F7
            td
                width: 170px
                height: 57px !important
                padding: 0 12px
                &:first-child
                    width: 40px
                &:nth-child(2)
                    .branchName
                        height: 20px
                        font-weight: 400
                        font-size: 14px
                        color: #1C1C1E
                &:nth-child(3)
                    .alertTypeContent
                        display: flex
                        flex-direction: row
                        align-items: center
                        .dot
                            width: 12px
                            height: 12px
                            background: #EE5253
                            border-radius: 56px
                            margin-right: 8px

                &:nth-child(4)
                    width: 120px
                    height: 100%
                    .filter
                        display: flex
                        align-items: center
                        flex-direction: row
                        .arrow_down
                            width: 13px
                            height: 13px
                            margin-right: 8px

                &:last-child
                    width: 108px
                    height: 100%
                    .action
                        width: 48px
                        height: 40px
                        background: #FFFFFF
                        border: 1px solid #E5E5EA
                        border-radius: 8px
                        display: flex
                        justify-content: center
                        align-items: center
                .checkbox
                    width: 16px
                    height: 16px
                    display: flex
                    justify-content: center
                    align-items: center
                    border: 1px solid #8E8E93
                    box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
                    border-radius: 4px
                    padding: 0 !important
                    cursor: pointer
            &:last-child
                box-shadow: none
.font14
    font-weight: 400
    font-size: 14px
    color: #1C1C1E
.dropdown
    width: 100% !important
    background-color: transparent !important
.dropdownTrigger
    background-color: #F9F9FB
    box-shadow: inset 0px 1px 0px #F2F2F7, inset 0px -1px 0px #F2F2F7
    span
        font-weight: 700
        font-size: 14px
        color: #8E8E93
.dropdownWrapper
    display: flex
    flex-direction: column
    background-color: #fff
    .dropdownElementWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 4px 12px
        cursor: pointer
        .dropdownElementLabel
            font-weight: 400
            font-size: 16px
            color: #1C1C1C

@import ../../../sass/colors

.wrapper
  display: flex
  width: 100%
  align-items: center
  background-color: white
  padding: 2rem 3rem
  color: $color-black-text
  cursor: pointer
  span
    margin: 0 1rem
    display: flex
    align-items: center
  .wrapper2
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    background-color: white
    cursor: pointer
    .body
      width: 100% !important
    .date
      font-size: 1.6rem
      display: flex
      align-items: center
      width: 20%
      .logo
        margin: 0 1rem
        width: 1.7rem
        height: 1.7rem
    .smsCount,.userCount
      width: 7%
      font-size: 1.6rem
    .phone
      display: flex
      align-items: center
      width: 15%
      font-size: 1.8rem
      .logo
        margin: 0 .5rem
        width: 1.7rem
        height: 1.7rem
    .user
      padding: .5rem 1rem
      border-radius: 4rem
      display: flex
      align-items: center
      width: 20%
      .userImg
        height: 4.5rem
        border-radius: 50%
      .userName,.points
        margin: 0 1rem
        font-size: 1.8rem

  .openArrow,.closeArrow
    border-style: solid
    border-color: black
    border-width: 0 2px 2px 0
    transform: rotate(45deg)
    display: block
    width: 2rem
    height: 1.6rem
    transition: all 500ms
    margin: -1rem 0 0 1rem
  .closeArrow
    transform: rotate(-45deg)
    margin-top: 0


.btn
  border: none !important
  box-shadow: none !important
  outline: none !important
  color: white !important
  font-size: 1.8rem !important
  width: 10rem !important
  height: 4rem !important
  background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
  border-radius: 20px !important


.deleteBtn
  font-size: 1.4rem
  margin: 0 2rem 0 0
  color: red


.cardBody
  display: flex
  .bodyWrapper
    width: 50%

    .dataWrapper
      display: flex
      justify-content: space-between
      align-items: flex-start
      .branches,.lastVisit,.visitsCount,.ticketSize
        display: flex
        flex-direction: column
        align-items: center
        .title,.text
          font-size: 1.8rem
          margin: 0
        .text
          margin: .5rem 0
        .allBranches
          margin: 0
          font-size: 1.2rem
          color: #FDBB11
          cursor: pointer
    .noIdea
      margin: 2rem 0
      font-size: 1.6rem


.readMore
  &:hover
    text-decoration: underline
  margin: 0
  font-size: 1.6rem
  color: #FDBB11
  cursor: pointer
/////branches modal popup
.modalHeader
  display: flex
  justify-content: space-between
  align-items: center
  padding: 2rem
  button span
    font-size: 3rem
  h5
    font-size: 2.4rem
    font-weight: bold
.modalBody
  height: 80vh
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: flex-start
  background-color: #fbfbfb
  overflow: auto
  .chip
    background-color: $color-shopx-main-theme
    border-radius: 5rem
    padding: 1rem 3rem
    margin: 1rem
    color: white
    font-size: 1.8rem

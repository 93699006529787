.main
    display: flex
    flex-direction: column
    background-color: #fff
    width: 100%
    border-radius: 4px
    .footer
        margin-top: 24px
        display: flex
        background: #F9F9FB
        box-shadow: inset 0px 1px 0px #F2F2F7
        justify-content: flex-end
        align-items: center
        padding: 12px 24px
        border-radius: 0 0 4px 4px
        .btnSave
            padding: 10px 24px
            width: 99px
            height: 48px
            background: #FFA351
            border-radius: 4px
            font-weight: 700
            font-size: 16px
            color: #FFFFFF
            outline: none

    .separator
        margin: 0 8px
    .formWrapper
        display: flex
        flex-direction: column
        padding: 0 24px    
    .shareInputsWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between    
        margin-top: 42.5px
        margin-bottom: 24px
    .header
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 18.5px 24px 0 24px
        .title
            font-weight: 700
            font-size: 20px
            color: #333333
        .iconWrapper
            padding: 4px
            width: 32px
            height: 32px
            background: #F2F2F7
            border-radius: 70px
            cursor: pointer
            .icon
                width: 24px
                height: 24px
.cpoTitle
    font-weight: 600
    font-size: 16px
    color: #1C1C1C
    margin-bottom: 4px
    .optional
        font-weight: 400
        font-size: 14px
        color: #65656C
        margin-left: 4px
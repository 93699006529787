.wrapper,.disabledWrapper
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    height: 48px
    margin-top: 8px
.disabledWrapper
    opacity: 0.6
    cursor: not-allowed

.dropdownWrapper
    display: flex
    flex-direction: column
    background-color: #fff
    .dropdownElementWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 4px 12px
        cursor: pointer
        .checkboxWrapper
            width: 24px
            height: 24px
            border: 1px solid #E5E5EA
            padding: 3px
            display: flex
            justify-content: center
            align-items: center
            box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
            border-radius: 4px
            margin-right: 12px
        .dropdownElementLabel
            font-weight: 400
            font-size: 16px
            color: #1C1C1C

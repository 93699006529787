@import ../../sass/colors

.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  margin: 1rem 0
  .body
    width: 80%
  .card
    border: none !important
  .btn,.btnActive
    border: none !important
    box-shadow: none !important
    outline: none !important
    color: black !important
    font-size: 1.8rem
    width: 100%
    background-color: white !important
    display: flex
    align-items: center
    justify-content: flex-start
    border-radius: 40px
    &:hover
      color: #FEBE19
      text-decoration: none
      background-color: rgba(254,190,25,.1)
      border-radius: 40px
    .bar
      display: flex
      margin: 0 1.8rem
      padding: .5rem 0
      padding: 0
      justify-content: flex-start
      align-items: center
      font-size: 2rem
      color: inherit
      span
        margin: 0 1rem
        font-size: 1.6rem
    .arrow,.arrowOpen
      margin: 0
      border-style: solid
      border-width: 0 2px 2px 0
      transform: rotate(45deg)
      display: block
      height: .7rem
      width: .7rem
      transition: all 1s
    .arrowOpen
      transform: rotate(-45deg)
  .btnActive
    background-color: rgba(254,190,25,.1) !important
    color: $color-shopx-main-theme !important

.NavigationItem:last-child
  margin: 1rem 0
  display: flex

.NavigationItem a
  color: black
  text-decoration: none
  display: block
  font-size: 1.4rem
  cursor: pointer


.NavigationItem a:hover
  color: #f09440
  text-decoration: none
  border-radius: 40px


.NavigationItem a:active,
.NavigationItem a.active
  color: #f09440
  text-decoration: none
  border-radius: 40px

.target
    font-size: 16px
    color: #f09440

.NavigationItem  
  a
    font-size: 1.4rem
    font-weight: 500
    color: #1a1a1a
    margin-bottom: 0.8rem

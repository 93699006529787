.wrapper
  width: 100%
  margin-bottom: 2.4rem
  .label
    font-size: 1.6rem
    color: #2d2d2d
    margin-bottom: 1rem
    font-weight: bold

  .radioButtonsWrapper
    display: flex
    .radioButton
      border: 1px solid #f09440
      color: #f09440
      flex: 1
      display: flex
      justify-content: center
      align-items: center
      height: 5rem
      font-size: 1.4rem
      font-weight: 500
      background-color: #ffffff
      transition: all .2s ease-in-out
      &.active,
      &:hover
        background-color: #f09440
        color: #ffffff
      &.invalid
        color: #db3030
        border-color: #db3030
      &:first-child
        border-top-left-radius: 5px
        border-bottom-left-radius: 5px
      &:last-child
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px

  .errorMsg
    color: #db3030
    font-size: 1.4rem
    margin-top: 1rem

.inputMain
    display: flex
    flex-direction: column
    width: 100%
    margin-top: 24px
    .formError
        font-size: 16px
        color: #FF0606
        font-weight: normal
        margin-top: 2px
    .label
        ont-weight: 600
        font-size: 16px
        color: #2D2D2D
    .inputWrapper
        width: 100%
        height: 56px
        background: #FFFFFF
        border: 1px solid #A6A6A6
        box-shadow: inset 1px 1px 2px 1px rgba(0, 0, 0, 0.1)
        border-radius: 4px
        margin-top: 10px
        display: flex !important
        align-items: center
        .postFix
            display: flex
            justify-content: center
            align-items: center
            padding: 17px 24px
            height: 100%
            background: #D1D1D6
            border-radius: 0px 5px 5px 0px
            font-weight: 800
            font-size: 16px
            line-height: 22px
            color: #1C1C1E
        input
            padding: 16px
            height: 100%
            outline: none
            border: none
            width: 100%
        input::placeholder
            font-size: 16px
            color: #8E8E93

.wrapper
    display: flex
    flex-direction: column
    width: 100%
    height: 60vh
    max-height: 80vh
    justify-content: center
    align-items: center
    .image
        width: 80px
        height: 80px
    .label
        font-size: 18px
        font-weight: 500
        color: #a6a6a6
        margin-top: 16px

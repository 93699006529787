@import ../../../sass/colors

.left
  right: unset !important
  left: 6px !important

.right
  left: unset !important
  right: 6px !important

.inputWrapper, .taggedInputWrapper
  display: flex
  width: 100%
  margin: 0
  justify-content: flex-start !important
  flex-direction: column

.taggedInputWrapper
  width: 55%

  .tagWrapper
    display: flex
    align-items: center

    .tag, .arTag
      margin: 0
      font-size: 1.4rem
      height: 5.5rem
      color: #2D2D2D
      font-weight: 500
      display: flex
      justify-content: center
      align-items: center
      padding: 0 2rem
      border-radius: 0 1rem 1rem 0
      background: #E3E3E3

    .arTag
      border-radius: 1rem 0 0 1rem !important

  span
    margin: 0 !important

.formError
  font-weight: bold
  font-size: 1.6rem
  color: #FF0606

.input, .passInput, .taggedInput, .arTaggedInput
  width: 100%
  outline: none
  border-radius: 5px
  font-size: 1.8rem
  font-weight: 500
  padding: 0 2rem
  color: $color-black-text
  border: 1px solid $color-border

  &.active
    border-color: #fdbb11

  &.valid
    border-color: #38c172

  &.invalid
    border-color: #db3030

.input::placeholder
  font-size: 1.6rem
  font-weight: normal

.passInput::placeholder
  font-size: 5rem !important
  letter-spacing: -4px

.taggedInput
  border-radius: 5px 0 0 5px

.arTaggedInput
  border-radius: 0 5px 5px 0

.input:read-only
  background-color: #e2e3e5

.dropDownPart
  width: 100%
  display: flex
  flex-direction: column
  margin: 0

  .switch
    font-size: 1.8rem !important
    height: 5.5rem !important
    margin: 0 !important
    width: 100%
    border-radius: 5px !important
    padding: 0 2rem !important
    outline: none !important
    background-color: white !important
    display: flex !important
    align-items: center !important
    justify-content: space-between !important
    color: black !important
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1) !important
    border: solid 1px #a6a6a6 !important

  .switch::after
    font-size: 2.5rem

  .switch:disabled
    border-radius: 5px !important
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1) !important
    border: solid 1px #e3e3e3 !important
    background: #ebebeb !important
    cursor: default

.menuWrapper
  width: 100%
  max-height: 30rem
  overflow: auto

  .item
    padding: 1rem 2rem
    border-radius: 5px !important
    font-size: 1.8rem
    outline: 0 !important

  .item:hover
    background-color: rgba(254, 190, 25, .1)

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

.datePickerWrapper
  width: 100%
  display: flex
  flex-direction: column
  margin: 0

  span
    font-size: 1.5rem
    position: relative
    margin: 0 !important
    color: #606977

  .datePicker
    display: flex
    align-items: center
    padding: 0
    position: relative
    width: 100%

    div:first-child
      width: 100%

      div
        bottom: 6rem

      .dateInput
        width: inherit
        height: 6rem
        border-radius: 5px
        padding: 0 1rem
        font-size: 1.8rem !important
        border: 1px solid $color-border !important
        color: black

      input:disabled
        border-radius: 5px !important
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1) !important
        border: solid 1px #e3e3e3 !important
        background: #ebebeb !important

      .time:disabled
        border-radius: 5px !important
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1) !important
        border: solid 1px #e3e3e3 !important
        background: #ebebeb !important

    .icon
      position: absolute
      right: 0
      font-size: 2rem
      padding: 0 1rem

.textAreaWrapper
  display: flex
  width: 100%
  margin: 1rem 0
  flex-direction: column

.textAreaLabel
  position: relative
  margin: 0
  color: #606977
  font-size: 1.6rem

  span
    margin: 0

  .formError
    color: #FF0606

.textArea
  height: 15rem
  outline: none
  resize: none
  border-radius: 5px
  font-size: 1.6rem
  padding: 1.5rem 2rem
  color: $color-black-text
  border: 1px solid $color-border

.textArea::placeholder
  color: #9b9b9b
  font-size: 1.6rem
  font-weight: 500

.uploadPart
  display: flex
  flex-direction: column
  .img
    width: 8.5rem
    height: 8.5rem

  .imgPickup
    z-index: 1 !important
    width: 12.5rem
    height: 12.5rem
    position: relative

  .foodIcon
    z-index: 2 !important
    width: 4.2rem
    height: 4.2rem
    position: absolute

  .uploadWrapperPickup
    margin: 0 !important

  .uploadWrapper, .uploadWrapperPickup
    display: flex
    // margin: 0 1.8rem
    flex-direction: column
    justify-content: center
    align-items: flex-start
    .label
      font-size: 2.4rem
      font-weight: 500
      letter-spacing: 0.25px
      text-align: center
      color: #a6a6a6
      display: flex

    .max
      font-size: 1.6rem
      font-weight: 500
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: 0.25px
      text-align: center
      color: #a6a6a6
.uploadCoverPart
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .img
    width: 57rem
    height: 15.5rem
    object-fit: contain
    border-radius: 5px
    cursor: pointer

  .uploadWrapper
    display: flex
    align-self: flex-end
    margin: 0
    flex-direction: column
    justify-content: center
    align-items: flex-end
    .max
      font-size: 1.2rem
      color: #A4A4A4
      margin: 1rem 0

  .uploadButton
    width: 57rem
    height: 4rem
    margin-top: 1.6rem
    background-color: #ffffff
    border-radius: 5px
    border: solid 1px #ee8728
    font-size: 1.4rem
    font-weight: bold
    line-height: 1.29
    letter-spacing: 0.1px
    text-align: center
    color: #ee8728
    cursor: pointer
    padding-top: 1.1rem

.colorPickerWrapper
  display: flex
  position: relative
  flex-direction: column
  width: 100%
  margin: 0

  .wrapper
    display: flex
    align-items: center

    .value
      height: 4.5rem
      width: 9rem
      display: flex
      align-items: center
      justify-content: center
      margin: 0 1rem
      cursor: pointer
      border-radius: 5px
      border: 1px solid #e3e3e3
      font-size: 1.6rem
      color: #9b9b9b

    .box
      cursor: pointer
      width: 4.5rem
      height: 4.5rem
      border-radius: 5px

  .label
    flex-direction: column !important

    .note
      color: #7d7d7d
      margin: 2rem 0
      font-size: 1.8rem
      font-weight: normal

.mapWrapper
  display: flex
  width: 100%
  margin: 0
  justify-content: flex-start !important
  flex-direction: column

.inputLocation, .inputLocationAr
  position: absolute
  top: 25%
  margin: 0 !important
  color: #2D2D2D

.inputLocation
  font-size: 2.4rem
  left: 2rem

.inputLocationAr
  right: 2rem

.inputSearch, .inputSearchAr
  position: absolute
  top: 25%
  margin: 0 !important
  color: #b2b2b2

.inputSearch
  right: 2rem

.inputSearchAr
  left: 2rem

.sugList
  display: flex
  flex-direction: column
  width: 100%
  position: absolute
  top: 5.5rem
  left: 0
  z-index: 30 !important
  padding: 1rem 0
  border-radius: 2px
  background: white
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .2), 0 2px 4px 0 rgba(0, 0, 0, .2)

  .sug
    display: flex
    align-items: center
    position: relative
    cursor: pointer

    .text, .textAr
      margin: 0
      padding: 1.5rem 0
      flex: 1
      border-bottom: 1px solid #e6e6e6
      font-weight: normal
      color: black
      height: 100%
      font-size: 1.3rem

    .textAr
      text-align: right

    .sugLocation, .sugLocationAr
      position: absolute
      top: 25%
      margin: 0 !important
      color: #b2b2b2
      justify-content: flex-start !important

    .sugLocation
      left: 2rem

    .sugLocationAr
      right: 2rem

  .sug:last-child
    .text
      border: 0 !important

  .sug:hover
    background: rgba(254, 190, 25, .1)

.overlay
  background: rgba(0, 0, 0, .3)
  z-index: 3331 !important

  .modal
    z-index: 3331 !important
    padding: 0
    min-width: 60rem
    display: flex
    flex-direction: column
    align-items: center
    min-height: 60rem
    border-radius: 20px
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2), 0 16px 16px 0 rgba(0, 0, 0, .3)
    border: solid 1px #979797
    overflow: hidden

    *
      margin: 0

    .btnWrapper
      width: 100%
      justify-content: flex-end
      display: flex
      margin: 2rem 0

      &.rtl
        justify-content: flex-start

        .btnCancel
          margin: 0 1.5rem

        .btnSave
          margin: 0

      .btnCancel, .btnSave
        outline: 0
        background: white
        border: 1px solid #fdbb11
        padding: 1rem 1.5rem
        font-size: 1.4rem
        font-weight: 900
        color: #fdbb11
        border-radius: 4rem

      .btnSave
        margin: 0 1.5rem
        color: white
        border: 0
        background: #fdbb11

    .cropHead
      color: #2D2D2D
      font-weight: 500
      font-size: 2.2rem
      width: 96%
      margin: 1rem 2rem

    .sliderWrapper
      margin: 3rem 0
      width: 100%
      display: flex
      align-items: center
      justify-content: center

      .minScale
        width: 2rem
        height: 2rem

      .maxScale
        width: 3rem
        height: 3rem

      .slider
        margin: 0 2rem
        padding: 0 !important
        display: flex
        width: 50%
        background-color: white !important

input:disabled
  // border-radius: 5px !important
  // box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1) !important
  // border: solid 1px #e3e3e3 !important
  color: #5e5e5e !important
  background-color: #ffffff !important

//chipssssssss

.chipsFieldWrapper
  display: flex
  width: 100%
  margin: 0
  flex-direction: column
  background-color: white
  border-radius: 5px

  .textWrapper
    display: flex
    align-items: center
    border-bottom: 1px solid $color-border

    h2
      font-size: 2rem
      font-weight: 600

  .chipsWrapper
    border: 1px solid #F5F5F5
    border-radius: 5px
    display: flex
    padding: .3rem 2rem
    width: 100%

    .error
      font-size: 1.6rem
      margin: 1rem 0
      color: red

    &:focus-within
      outline: none
      border-color: #fdbb11

.inputAndLoadingWrapper
  display: flex
  width: 100%
  align-items: center
  border-radius: 5px
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1)
  border: solid 1px #a6a6a6
  background-color: #ffffff
  padding: 0 2rem
  height: 5.7rem

  .input
    border: none
    padding: 0
    flex: 1
  img
    width: 2.4rem
    height: 2.4rem

.passwordMaskWrapper
  display: flex
  height: 5.5rem

.photoUploaderWrapper,.photoUploaderWrapperPickup
  display: flex
  flex-direction: column
  // justify-content: center
  // align-items: center
  // width: 87rem;
  .label
    font-size: 2.4rem
    font-weight: 500
    letter-spacing: 0.25px
    text-align: center
    color: #222222

  .max
    font-size: 1.6rem
    font-weight: 500
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: 0.25px
    text-align: center
    color: #222222

  .uploadFBtn
    cursor: pointer
    width: 57rem
    height: 4rem
    display: flex
    justify-content: center
    border-radius: 5px
    border: solid 1px #ee8728

@import ../../../sass/colors
.toggleWrapper
  display: flex
  flex-direction: row
  width: 100%
  padding: 2.4rem 3rem 0 3rem
  align-items: center
  justify-content: flex-end
.activeMerchantDealsWrapper
  display: flex
  padding: 2.4rem 3rem
.commissionWrapper
  display: flex
  flex-direction: row
  padding: 2.4rem 3rem
  align-items: flex-start
  .accountTypeWrapper
    width: 48%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    padding: 24px
    border: solid 1px #e5e5ea
    background-color: #fff
  .cardTitle
    font-size: 24px !important
    font-weight: bold !important
    color: #1c1c1e !important
.cards
  display: grid
  grid-template-columns: repeat(3, auto)
  padding: 24px

  .wrapper
    margin: 2.5rem
    display: flex
    justify-content: center
    align-items: center
    border-radius: .5rem
    width: 36.6rem
    height: 13.3rem

    span
      font-size: 2rem
.brandProgressWrapper
  display: flex
  flex-direction: row
  align-items: center
  padding: 0 24px
  margin-top: 24px
  .brandProgress
    font-size: 28px
    font-weight: bold
    margin-left: 12px
    color: #1c1c1e
  .rocketWrapper
    width: 48px
    height: 48px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 12px
    border-radius: 4px
    background-color: #1c1c1e
    .rocket
      width: 24px
      height: 24px

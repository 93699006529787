* {
  margin: 0;
  padding: 0;
}
* button {
  cursor: pointer;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  cursor: inherit;
}

#notificationsWrapper {
  font-size: unset;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 117.5em) {
  html {
    font-size: 70%;
  }
}
@media only screen and (max-width: 96em) {
  html {
    font-size: 62.5%;
  }
}
@media only screen and (max-width: 90em) {
  html {
    font-size: 53%;
  }
}
@media only screen and (max-width: 68.75em) {
  html {
    font-size: 43%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 40%;
  }
}
@media only screen and (max-width: 46.875em) {
  html {
    font-size: 30%;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 25%;
  }
}
@media only screen and (max-width: 25em) {
  html {
    font-size: 15%;
  }
}
.main
    display: flex
    flex-direction: column
    background-color: #fff
    border: 1px solid #E5E5EA
    border-radius: 8px
    width: 100%
    max-width: 403px
    margin-left: 48px
    .loaderWrapper
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
    .resolvedIssuesWrapper
        display: flex !important
        flex-direction: column !important
        overflow: scroll !important
        overflow-y: scroll
        height: 360px !important
        max-height: 360px !important
        .emptyWrapper
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            .infoIcon
                width: 88px
                height: 88px
            .thereAreNoTasksResolved
                margin-top: 24px
                font-weight: 400
                font-size: 14px
                color: #1C1C1C
        .resolvedIssueWrapper
            display: flex
            flex-direction: column
            padding: 16px 24px
            border-bottom: 2px solid #F2F2F7
            &:last-child
                border-bottom: 0
            .resolvedIssueTitle, .resolvedIssueMessage
                font-weight: 400
                font-size: 12px
                color: #65656C
                margin-bottom: 8px
            .resolvedIssueMessage
                font-size: 14px
                margin-bottom: 0px
    .resolvedIssuesWrapper::-webkit-scrollbar
        width: 0

    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 24.5px
        border-bottom: 1px solid #E5E5EA
        .resolvedIcon
            width: 32px
            height: 32px
            border-radius: 50%
            margin-right: 8px
            display: flex
            justify-content: center
            align-items: center
            background-color: #E7F8EE
            .ic_check
                width: 20px
                height: 20px
        .resolvedTitle
            font-weight: 400
            font-size: 20px
            color: #1C1C1E
            margin-left: 8.5px
            span
                color: #65656C

$color-border: #E3E3E3
$color-black-text: #2D2D2D

.fullTimeWrapper
  display: flex
  flex-direction: column
  background-color: white
  //border: 1px solid $color-border
  border-radius: 5px
  user-select: none
  .textWrapper
    //padding: 2rem 3rem
    display: flex
    //align-items: center
    //border-bottom: 1px solid $color-border
    h2
      font-size: 1.6rem
      font-weight: 600
      //margin-bottom: 1rem
  .pickersWrapper
    margin-bottom: 1.6rem
    .datePicker
      display: flex
      width: 44.5rem
      margin: 1rem 0
      align-items: center
      position: relative
      .date
        height: 5.5rem
        padding: 2rem
        font-size: 1.6rem !important
        width: 44.5rem
        outline: 0
        color: #B1B1B1
      .date:disabled
        background-color: #F3F3F3
      .icon
        position: absolute
        right: 0
    .timePicker
      display: flex
      align-items: center
      width: 21rem
      position: relative
      margin-bottom: 1.6rem
      ul
        background-color: #fff
        z-index: 1
      input,.time
        height: 5.5rem
        outline: 0
        // padding: 2rem
        font-size: 1.6rem !important
        width: 21rem
        color: #B1B1B1
      input:disabled
        background-color: #F3F3F3
      .time:disabled
        background-color: #F3F3F3
      .icon
        position: absolute
        right: 0

.icon
  font-size: 2rem
  margin: 0 1rem

input
  width: 35%
  background-color: white
  font-size: 2rem !important
  border: 1px solid $color-border
  height: 5rem
  padding: 1rem 2rem
  border-radius: .4rem
  color: $color-black-text !important
input:disabled
  background-color: #F3F3F3

input::placeholder
  color: #989A97

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0
input:disabled
  background-color: #F3F3F3

.formError
  font-weight: bold !important
  font-size: 1.6rem !important
  color: #db3030 !important

.invalid
  border-color: #db3030 !important

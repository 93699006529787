.fileWrapper
  display: flex
  flex-direction: column
  span  
    font-size: 1.6rem
    font-weight: bold
    color: #2d2d2d
    margin-bottom: 1rem
  .labelFile
    width: 16.6rem
    height: 4rem
    border-radius: 0.4rem
    background-color: #f09440
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    span
      font-size: 1.4rem
      font-weight: bold
      color: #ffffff
      margin-bottom: 0


.wrapper
  width: 656px
  //box-shadow: -0.2rem 0.2rem 5.4rem 0 rgba(0, 0, 0, 0.1)
  background-color: #ffffff
  display: flex
  flex-direction: column

  .loader
    display: flex
    flex: 1
    margin: 20rem
    align-self: center
  .header
    display: flex
    justify-content: space-between
    padding: 12.5rem 3.3rem
    padding-bottom: 4rem
    .headerText
      font-size: 2.4rem
      font-weight: 900
      line-height: 0.83
      color: #000000
    .headerIcon
      width: 1.9rem
      height: 1.9rem
      cursor: pointer
  form
    .select, .promoCodeRank
      width: 100%
    .promoCodeRank
      width: 45%
      padding: 0 24px
    .brand
      display: flex
      .addBtn
        width: 11.6rem
        height: 4.8rem
        border-radius: 4px
        background-color: #f09440
        font-size: 1.6rem
        font-weight: bold
        line-height: 1.13
        letter-spacing: 0.11px
        text-align: center
        color: #ffffff
        margin-left: 0.8rem !important
        margin-top: 5.5rem !important

.chipsWrapper
  display: flex
  flex-wrap: wrap

.promoType
  padding: 24px 0
  .promoTypeWrapper
    border-top: 1px solid #F2F2F7 !important
    padding: 24px
    padding-bottom: 8px
    .promoTypeDropdownTitle
      font-size: 20px
      font-weight: bold
      color: #8e8e93
    .promoTypeDropdown
      margin-bottom: 0 !important
      width: 100%
      margin-top: 24px !important
    .promoTypeDropdownIcon
      width: 24px
      height: 24px
  .pointsMultiplierWrapper
    padding: 0px 24px
    padding-top: 32px
  span
    font-size: 16px
    font-weight: bold
    color: #2d2d2d
  ul
    display: flex
    align-items: center
    justify-content: space-between
    list-style: none
    li
      border: solid 1px #f09440
      font-size: 1.4rem
      font-weight: 500
      cursor: pointer
    .active
      background-color: #f09440
      color: #ffffff
    .unActive
      background-color: none
      color: #f09440
    .l1
      border-radius: 5px 0 0 5px
      padding: 1.5rem 3.4rem
      border-right-width: 0
    .l2
      padding: 1.5rem 2.8rem
      border-right-width: 0
    .l3
      border-radius: 0 5px 5px 0
      padding: 1.5rem 2.72rem
.max
  width: 28rem
  border: red
.inactive
  background-color: #fafafa
  .SwitchContent
    .SwitchSpan
      color: #1a1a1a
.active
  background-color: rgba(56, 193, 114 , 0.12)
  .SwitchContent
    .SwitchSpan
      color: #38c172
.activePreselected
  background-color: rgba(240, 148, 64,0.1)
  .SwitchContent
    .SwitchSpan
      color: rgba(26, 26, 26, 1)
.SwitchWrapper
  width: 100%
  border-radius: 12px
  display: flex !important
  padding: 24px
  align-items: flex-start
  justify-content: space-between !important
  .SwitchContent
    padding: 0 7rem 0 1rem
    .SwitchSpan
      font-size: 1.6rem
      font-weight: 900

    .SwitchP
      font-size: 1.4rem
      color: rgba(26, 26, 26, 0.5)
.priceWrapper
  display: flex
  justify-content: space-between
  margin-top: 24px
  align-items: center
  .secondInput
    width: 21.2rem
  .dateError
    transform: translateY(-5px)
    margin: 43px 10px 5px 6px
    color: #FF0606
    font-weight: 400
    font-size: 1.5rem

.submitBtn
  width: 100%
  height: 5.6rem
  border-radius: 0.4rem
  font-size: 1.8rem
  font-weight: bold
  letter-spacing: 0.13px
  text-align: center
.SelectUsersWrapper
  margin-top: 1rem
  .text
    font-size: 16px
    font-weight: bold
    color: #2d2d2d
.fileWrapper
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 1rem
  .fileLab
    font-size: 1.6rem
    font-weight: 500
    color: #f09440
    // text-decoration:
.fileName
  font-size: 1.2rem
  font-weight: 500
  color: #1a1a1a
.promocodeSettingsTitle
  margin-top: 32px
  margin-left: 28px
  margin-bottom: 24px
  font-size: 18px
  font-weight: 900
  letter-spacing: 0.4px
  color: #a6a6a6
.promoCodeType
  span
    margin-bottom: 10px
    font-size: 16px
    font-weight: bold
    color: #2d2d2d
  .ul
    display: flex
    list-style: none
    justify-content: normal
    align-items: normal
    width: 100%
    margin-top: 10px
    margin-bottom: 32px
    li
      border: solid 1px #f09440
      font-size: 1.4rem
      font-weight: 500
      cursor: pointer
      width: 33%
    .active
      background-color: #f09440
      color: #ffffff
    .unActive
      background-color: none
      color: #f09440
    .l1
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      border-right-width: 0
    .l2
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      border-right-width: 0
    .l3
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
    .preselectedPromoWrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding: 20px 30px 16px 32px
      background-color: #FDF4EB
.promocodeDurationInputWrapper
  width: 100%
  padding: 0 30px
  .promocodeDurationInput
    width: 100%
.promoCodeSplitWrapper
  padding: 14px 24px
  border-bottom: 1px solid #e6e6e6
  .brandInfoWrapper
    display: flex
.promoCodeType
  margin-top: 0.5rem
.promoTypeList
  margin-top: 1rem
.storeLabel
  font-size: 2.2rem !important
  font-weight: bold !important
  color: #2d2d2d !important
.select , .promoTypeList , .priceWrapper , .max ,.promoCodeType , .SelectUsersWrapper ,.fileWrapper
  padding: 0 24px
.countryWrapper
  width: 100%

.spanHeader
  padding: 3.5rem !important
  font-size: 1.8rem !important
  font-weight: 900 !important
  letter-spacing: 0.4px !important
  color: #a6a6a6 !important

.brandImage
  width: 3.2rem
  height: 3.2rem
  border-radius: 3.2rem
  border: solid 0.5px #bfbfbf
  background-color: #ffffff

.applySplitForAllContainer
  width: 100%
  height: auto
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  align-content: center
  padding: 1.4rem 3.4rem

.applySplitForAllContainer
  color: #1a1a1a
  font-size: 1.6rem
  font-weight: 900

.applySplitForAllToggle
  margin-left: 16.4rem
  direction: ltr !important
  width: 47px !important
  height: 25px !important

.switch
  border: solid 1.5px rgba(0, 0, 0, 0.1)
.settingsWrapper
  margin-top: 7.4rem
  .settingWrapper
    display: flex
    cursor: pointer
    align-items: center
    justify-content: space-between
    margin-bottom: 1.1rem
    border-bottom: 1px solid #ebebeb
    padding-bottom: 0.5rem
    .settingText
      .settingTitle
        margin-bottom: 0.4rem
        font-size: 1.8rem
        font-weight: 500
        color: #2d2d2d
      .settingSubTitle
        font-size: 1.2rem
        color: #9b9b9b
    .settingSwitch
      margin-left: 16.4rem
      direction: ltr !important
      border: solid 1.5px rgba(0, 0, 0, 0.1)

.settingsWrapper.rtl
  .settingWrapper
    .settingSwitch
      margin-left: 0
      margin-right: 16.4rem
      border: solid 1.5px rgba(0, 0, 0, 0.1)

.settingSwitch.rtl
  transform: scale(-1) !important
.cappingInput
  margin-top: 0 !important

@import "../../BannerCards/AddBannerCard/form-ui"
.wrapper
  width: 51.2rem
  padding: 12rem 3rem 0
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  @include form-shared
  .imageUploadWrapper
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    .dropZoneWrapper,
    .imagePreviewWrapper
      width: 21.5rem
      height: 26.7rem



.collapseWrapper
    background-color: #fff
    padding: 0 24px
    .headerWrapper
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        border: 1px solid #E5E5EA
        background-color: #fff
        height: 71px
        box-shadow: 0px 2px 4px rgba(142, 142, 147, 0.12)
        border-radius: 8px
        padding: 12px 24px
        .rightSideWrapper
            display: flex
            flex-direction: row
            align-items: center
            .collapseText
                font-weight: 400
                font-size: 14px
                color: #65656C
            .chevronIcon
                width: 16px
                height: 16px
        .leftSideWrapper
            display: flex
            flex-direction: row
            align-items: center
            .checkbox
                display: flex
                justify-content: center
                align-items: center
                cursor: pointer
                width: 16px
                height: 16px
                border: 1px solid #8E8E93
                box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
                border-radius: 4px
                margin-right: 16px
            .brandInfoWrapper
                display: flex
                flex-direction: row
                align-items: center
                .brandImageWrapper
                    width: 40px
                    height: 40px
                    border: 1px solid #D9D9D9
                    border-radius: 50%
                    .brandImage
                        width: 39px
                        height: 39px
                        border-radius: 50%
                .brandDetailsWrapper
                    display: flex
                    flex-direction: column
                    margin-left: 16px
                    .brandName
                        font-size: 18px
                        font-weight: 700
                        color: #1C1C1C
                    .branchesNeedActionWrapper
                        display: flex
                        flex-direction: row
                        align-items: center
                        .alertIcon
                            width: 16px
                            height: 16px
                            margin-right: 6px
                        .branchesNeedAction
                            color: #65656C
                            font-size: 14px
                            font-weight: 400
.collapsePanel
    border: none
    background-color: #fff

.branchesNeedActionContent
    background-color: #F9F9FB
    border: 1px solid #E5E5EA
    border-radius: 8px
    width: 100%
    max-height: 268px
    overflow-y: scroll
    padding: 8px 16px
    margin-bottom: 12px 
.branchesNeedActionContent::-webkit-scrollbar
    width: 6px
.branchesNeedActionContent::-webkit-scrollbar-thumb    
    background-color: #D1D1D6
    border-radius: 8px

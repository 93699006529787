.brandHeaderWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: "16px 12px"
    border-bottom: "1px solid #E5E5EA"
    .chevron
        width: 24px
        height: 24px
        cursor: pointer
    .brandNameWrapper
        display: flex
        flex-direction: row
        align-items: center
        .brandName
            font-weight: 400
            font-size: 14px
            color: #1C1C1C
.checkboxWrapper
    display: flex
    justify-content: center
    align-items: center
    width: 16px
    height: 16px
    margin-right: 8px
    background: #1C1C1E
    border: 1px solid #8E8E93
    box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
    border-radius: 4px

.wrapper, .dimmedWrapper
  min-width: 14rem
  height: 4rem
  border-radius: 2rem
  background-color: #41126c
  padding: 1.1rem 1.6rem
  display: flex
  align-items: center
  justify-content: space-between
  margin-right: 1.6rem
  margin-bottom: 1.6rem
  .sp
    font-size: 1.6rem
    font-weight: 900
    line-height: 1.19
    color: #ffffff
  .im
    width: 1.8rem
    height: 1.8rem
    cursor: pointer
.dimmedWrapper
  background-color: #dedede
  cursor: not-allowed
  .im
    cursor: not-allowed
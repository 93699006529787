.inputLabel
    font-weight: 600
    font-size: 16px
    color: #2D2D2D
.promoCodeErrorMessage
    margin-top: 8px
    font-size: 16px
    color: #FF0606
.rankInput
    display: flex !important
    flex-direction: column !important
    margin-top: 24px !important
    width: 129px !important

@import ../../../sass/colors

.wrapper
  display: flex !important
  align-items: center
  background-color: white
  .labelWrapper
    .title
      margin: 1rem
      color: #2d2d2d
      font-weight: 500
      font-size: 1.8rem
  .input
    display: none
  .input[type="radio"] + span
    margin: 0
    padding: 0 !important
    width: 2.4rem !important
    border: solid 1px #a6a6a6
    background-color: #ffffff
    height: 2.4rem !important
    border: 1px solid #2d2d2d
    border-radius: 1.6rem
    cursor: pointer
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.07)
    display: flex !important
    align-items: center !important
    justify-content: center !important

.checkWrapper
  display: flex
  align-items: center
  justify-content: center
  .check
    width: 1.9rem !important
    height: 1.9rem !important
    border-radius: 1.6rem !important
    background-color: #f09440


.wrapper
  box-shadow: 0 7px 14px 0 rgba(7, 4, 4, 0.1)
  background-color: #fefefe
  margin-bottom: 2rem
  margin-top: 2rem
  .header
    padding: 1.7rem 3.5rem
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #ebebeb
    .success
      font-size: 1.4rem
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      flex: 0.9

    .open
      font-size: 1.4rem
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      flex: 0.9

    .status
      font-size: 1.4rem
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      flex: 0.9
    .body
      font-size: 1.4rem
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      flex: 2.5
    .date
      font-size: 1.4rem
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      flex: 1
    .text
      font-size: 1.4rem
      font-weight: 900
      letter-spacing: 0.7px
      color: #9b9b9b
      flex: 1
  .headWrapper
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1.5rem 3rem
    border-bottom: 1px solid #ebebeb
    .pushNotificationCount
      font-size: 2.2rem
      color: #000000
    .icon
      width: 2rem
      height: 2rem
      object-fit: contain
    .createBtn
      width: 19.7rem
      height: 5.6rem
      outline: none
      border-radius: .5rem
      background-color: #41126c
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff

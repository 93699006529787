.generic-select {
  width: auto !important;
  max-height: 40px !important;
}
.generic-select .ant-select-selector {
  background-color: #f2f2f7 !important;
  border-radius: 50px !important;
  height: 100% !important;
}
.generic-dropdown {
  border-radius: 8px !important;
  width: auto !important;
}
.generic-dropdown .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner .ant-select-item-option-active {
  background-color: #fff !important;
  padding: 0 !important;
}

.dropDownSelect
  display: flex
  justify-content: center
  align-items: center
  justify-content: space-around
  padding: 8px
  height: 40px
  border: 1px solid #ebebeb
  .dropdownIcon
    width: 24px
    height: 24px
    margin: 0 0 0 24px

  .dropdownItemSelected
    display: flex
    flex-direction: row

    align-items: center
    .dropdownLabel
      font-size: 16px
      font-weight: 500
      color: #1a1a1a
      margin: 0 4px
    .dropdownValue
      color: #595959
      font-size: 16px
.dropdownItem
  display: flex
  justify-content: flex-start
  padding: 9px 16px 9px 16px
  border-bottom: 1px solid rgba(0,0,0,.15)
  .dropdownItemLabel
    font-size: 16px
  &:active
    background-color: transparent
.filterWrapper
    display: flex
    flex-direction: row
    width: 100%
    align-items: center
    justify-content: flex-end
    padding: 1.5rem 3rem     
    padding-top: 3.5rem    
    .separator
      margin: 0 4px 
.countryDropdown
  display: flex !important
  justify-content: center !important
.branchDropdwon
  display: flex !important
  justify-content: flex-end  !important 

.main
    display: flex
    justify-content: center
    align-items: center
    .wrapper
        display: flex
        flex-direction: column
        width: 58.4rem
        border-radius: 10px
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
        background-color: #ffffff
        .btnWrapper
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: flex-end
            height: 80px
            margin: 24px 0 0
            padding: 16px 24px
            border-radius: 0 0 10px 10px
            background-color: #f2f2f2
            .overwriteButton, .cancelButton
                height: 48px
                outline: none
                margin: 0 0 0 8px
                padding: 15px 40px
                display: flex
                justify-content: center
                align-items: center
                border-radius: 5px
                background-color: #db3030
                font-size: 14px
                font-weight: bold
                color: #ffffff
            .cancelButton
                border: solid 1px #ee8728
                background-color: #f2f2f2
                color: #ee8728

        .container
            display: flex
            flex-direction: column
            padding: 3.2rem
            .title
                padding-bottom: 2.4rem
                font-size: 2.2rem
                font-weight: 500
                color: #2d2d2d
            .contentWrapper
                display: flex
                flex-direction: row
                align-items: center
                padding: 24px
                border-radius: 5px
                background-color: rgba(218,48,49,0.2)
                width: 100%
                .rank
                    font-size: 2.1rem
                    font-weight: 500
                    color: #db3030
                .errorImage
                    width: 3.2rem
                    height: 3.2rem
                    margin: 0 2.4rem 0 0

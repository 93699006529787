@import ../../sass/colors

.wrapper
  display: flex
  min-height: 100vh
  flex-direction: column
  justify-content: center
  align-items: center
  .lineWrapper
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.8rem
    color: $color-grey-paragraph
    img
      width: 2rem
      margin: 0 .5rem
  .logoWrapper
    width: 8rem
  .formWrapper
    width: 50%
    padding: 8rem 5rem 4rem
    margin: 5rem 0
    border-top: .8rem solid $color-shopx-main-theme
    display: flex
    box-shadow: $box-shadow-light
    background-color: white
    flex-direction: column
    align-items: center
    .input
      height: 7rem
      width: 90%
      outline: none
      margin-bottom: 2.5rem
      font-size: 2rem
      border-radius: 1rem
      padding: 0 1rem
      color: $color-black-text
      border: 1px solid $color-border
      text-align: left
    .btn,.btnDisabled
      width: 15rem
      height: 6rem
      font-size: 1.8rem !important
      border: none
      outline: 0
      color: white !important
      border-radius: 4rem
      cursor: pointer
      transition: all .2s
      outline: none !important
    .btnDisabled
      background-color: $color-grey-paragraph
      cursor: not-allowed
    .btn
      background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)

    .btn:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover
    .btn:active
      box-shadow: $box-shadow-btn-active
      transform: translateY(-1px)

  .label
    position: relative
    width: 90%
    font-size: 1.5rem


